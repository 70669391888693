import React from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { adminService } from '../../services/admin.service'
import FormButton from '../../components/GUI/FormButton'
import LoadingIndicator from '../../components/GUI/LoadingIndicator'
import styles from '../../styles/EmailCompanies.module.less'
import { exposeErrors } from '../../utils/errors'

const validationSchema = yup.object().shape({
    subject: yup.string().required(),
    text: yup.string().required(),
    html: yup.string().required(),
    isLegallySignificant: yup.boolean().required(),
    sendOnlyToMe: yup.boolean().required(),
    pricingPlanIds: yup.array(),
    limitPricingPlans: yup.boolean()
})

const htmlBaseTemplate = `<table width="100%" cellpadding="0" cellspacing="0">
    <tr>
        <td class="content-block">
            Dear Seats.io customer,
        </td>
    </tr>
    <tr>
        <td class="content-block">
            Add some content here. For extra paragraphs, add additional <tr><td>content</td></tr> below the current </tr>
        </td>
    </tr>
    <tr>
        <td class="content-block">
            Thank you for being a customer of seats.io.
        </td>
    </tr>
    <tr>
        <td class="content-block">
            Kind regards,<br/>
            The <a href="https://www.seats.io">Seats.io</a> team
        </td>
    </tr>
</table>
`

const textBaseTemplate = `Dear Seats.io customer,

Kind regards,
The Seats.io team
https://www.seats.io
`

class EmailCompanies extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            initialValues: {
                subject: '',
                text: textBaseTemplate,
                html: htmlBaseTemplate,
                isLegallySignificant: false,
                sendOnlyToMe: true,
                limitPricingPlans: false,
                pricingPlanIds: []
            },
            sendResults: {},
            pricingPlans: []
        }
    }

    componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin/companies',
                icon: 'icon-arrow-light-left',
                string_key: 'companies',
                className: 'soft-button'
            }]
        })
        exposeErrors(adminService.getPricingPlans(), null, this.state)
            .then(pricingPlans => {
                const plans = this.formatPricingPlans(pricingPlans)
                this.setState({pricingPlans: plans})
            })
    }

    formatPricingPlans (pricingPlans) {
        const uniquePlans = {}
        pricingPlans.filter(plan => plan.name.toLowerCase().indexOf("legacy") === -1 && plan.name.toLowerCase().indexOf("custom") === -1).forEach(plan => uniquePlans[plan.id] = plan.name)
        const plans = []
        for (const [key, value] of Object.entries(uniquePlans)) {
            plans.push({id: parseInt(key), name: value});
        }
        return plans.sort((a, b) => a.id - b.id)
    }

    errorClassName (props, fieldName) {
        return props.errors[fieldName] ? 'error' : null
    }

    renderControlPanel (props) {
        return <div className="top-panels">
            <div className="top-panels">
                <div className="controls-panel">
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="subject">Subject</label>
                        </div>
                        <div className="field">
                            <input type="text" placeholder="Say something descriptive"
                                id="subject" name="subject" onChange={props.handleChange}
                                value={props.values.subject} className={this.errorClassName(props, 'subject')}/>
                        </div>
                    </div>
                    <div className="flex-wrapper">
                        <span>
                            <input type="checkbox" name="isLegallySignificant"
                                onChange={props.handleChange} value={props.values.isLegallySignificant}/>
                            <span className="checkbox-label">
                                <label htmlFor="isLegallySignificant">Legally significant</label>
                            </span>
                        </span>
                        <span>
                            <input type="checkbox" name="sendOnlyToMe" defaultChecked={props.values.sendOnlyToMe}
                                onChange={props.handleChange} value={props.values.sendOnlyToMe}/>
                            <span className="checkbox-label">
                                <label htmlFor="sendOnlyToMe">Send only to me</label>
                            </span>
                        </span>
                    </div>
                    <div className="flex-wrapper">
                        <span key="limitPricingPlans">
                            <input type="checkbox" name="limitPricingPlans"
                                defaultChecked={props.values.limitPricingPlans}
                                onChange={props.handleChange} value={props.values.limitPricingPlans} className={this.errorClassName(props, 'limitPricingPlans')}>
                            </input>
                            <label className="checkbox-label">{`Limit pricing plans${props.values.limitPricingPlans ? ': ' : ''}`}</label>
                        </span>
                        {props.values.limitPricingPlans && <span>
                            {this.state.pricingPlans.map(plan => <span key={`plan-${plan.name}`}>
                                <input id={`${plan.name}`} name={`${plan.name}`} type="checkbox"
                                    checked={props.values.pricingPlanIds.indexOf(plan.id) !== -1}
                                    onChange={e => {
                                        props.handleChange(e)
                                        this.handlePricingPlanClick(plan.id, props.values.pricingPlanIds)
                                    }}/>
                                <span className="checkbox-label">
                                    <label htmlFor={plan.name}>{plan.name}</label>
                                </span>
                            </span>)}
                        </span>}
                    </div>
                </div>
            </div>
        </div>
    }

    handlePricingPlanClick (pricingPlanId, values) {
        const index = values.indexOf(pricingPlanId)
        if (index === -1) {
            values.push(pricingPlanId)
        } else {
            values.splice(index, 1)
        }
    }

    renderContentInput (props) {
        return <div>
            <div className="form-field">
                <span className="checkbox-label">
                    <label htmlFor="html">HTML content</label>
                </span>
                <div className="field">
                    <textarea id="html" name="html" onChange={props.handleChange} cols="80" rows="15"
                        value={props.values.html} className={this.errorClassName(props, 'html')}>
                    </textarea>
                </div>
            </div>
            <div className="form-field">
                <span className="checkbox-label">
                    <label htmlFor="text">Text content</label>
                </span>
                <div className="field">
                    <textarea id="text" name="text" onChange={props.handleChange} cols="80" rows="15"
                        value={props.values.text} className={this.errorClassName(props, 'text')}/>
                </div>
            </div>
        </div>
    }

    renderSendResults() {
        const results = []
        for (const property in this.state.sendResults) {
            results.push(<tr><td>{`${property}`}</td><td>{`Email ${this.state.sendResults[property] ? 'sent successfully' : 'Email sending failed'}`}</td></tr>)
        }
        return results.length > 0 && <div>
            <table>
                <thead>
                    <th>Region</th>
                    <th>Result</th>
                </thead>
                <tbody>
                    {results}
                </tbody>
            </table>
        </div>
    }

    render () {
        const readyToSend = this.state.subject && this.state.text && this.state.html
        return <div className={styles.EmailCompanies}>
            <div className="sectioned-page">
                <div className={`sectioned-page-container ${styles.container}`}>
                    <Formik initialValues={this.state.initialValues}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validate={(values) => {
                            const errors = {};
                            if (values.limitPricingPlans && values.pricingPlanIds.length === 0) {
                                errors.limitPricingPlans = 'At least one pricing plan is required'
                            }
                            return errors
                        }}
                        onSubmit={async (values) => {
                            const sendResults = await adminService.emailCompanies(values.subject, values.isLegallySignificant, values.sendOnlyToMe, values.text, values.html, values.pricingPlanIds)
                            this.setState({sendResults})
                        }}>
                        {props =>
                            <form className="newStyle" onSubmit={props.handleSubmit}>
                                <div className="section">
                                    {this.renderControlPanel(props)}
                                </div>
                                <div className="section">
                                    {this.renderContentInput(props)}
                                </div>
                                <div className="form-actions">
                                    {props.isSubmitting
                                        ? <LoadingIndicator/>
                                        :  <FormButton className={styles.button} onSubmit={props.handleSubmit} type="submit">Send</FormButton>
                                    }
                                </div>
                                <div>
                                    {this.renderSendResults()}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
            </div>
        </div>
    }
}

EmailCompanies.propTypes = {
    setHeader: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(EmailCompanies)
