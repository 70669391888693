import { getClient } from './base.service'

export const webhooksService = {
    generatePortalUrl,
    enableWebhooks,
    disableWebhooks
}

function generatePortalUrl () {
    return getClient().webhooks.generatePortalUrl()
}

function enableWebhooks () {
    return getClient().webhooks.enable()
}

function disableWebhooks () {
    return getClient().webhooks.disable()
}
