// @ts-check
import React, { Component } from 'react'
import { history } from '../../store'
import { SeatsioDesigner } from '@seatsio/seatsio-react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { chartJSUrlOfActiveCompany } from '../../domain/regions'

class ViewPublishedChart extends Component {
    render () {
        const { match: { params: { chartKey } } } = this.props
        return (
            <div className="chartDesignerContainer">
                <div className="designer">
                    <SeatsioDesigner
                        region="eu"
                        chartKey={chartKey}
                        secretKey={this.props.secretKey}
                        onExitRequested={() => history.push('/')}
                        chartJsUrl={chartJSUrlOfActiveCompany()}
                        openLatestDrawing={false}
                        // @ts-expect-error
                        workspaceKey={this.props.workspaceKey}
                        isWebapp={true}
                    />
                </div>
            </div>
        )
    }
}

ViewPublishedChart.propTypes = {
    match: PropTypes.object.isRequired,
    secretKey: PropTypes.string.isRequired,
    workspaceKey: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    secretKey: state.user.secretKey,
    workspaceKey: state.context.workspace.key
})

export default connect(mapStateToProps, null)(ViewPublishedChart)
