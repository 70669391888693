// @ts-check
import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { resetHeader } from '../../actions/header'
import { history } from '../../store'
import { SeatsioDesigner } from '@seatsio/seatsio-react'
import { setPendingNewChart } from '../../actions/charts'
import { chartJSUrlOfActiveCompany } from '../../domain/regions'

class CreateChart extends Component {
    componentDidMount () {
        this.props.resetHeader()
    }

    onChartCreated (chartKey) {
        window.history.replaceState(history.location.state, 'Seats.io', `/charts/${chartKey}/edit`)
        this.props.setPendingNewChart()
    }

    render () {
        return (
            <div className="chartDesignerContainer">
                <div className="designer">
                    <SeatsioDesigner
                        region="eu"
                        secretKey={this.props.secretKey}
                        onExitRequested={() => history.push('/')}
                        chartJsUrl={chartJSUrlOfActiveCompany()}
                        onChartCreated={chartKey => this.onChartCreated(chartKey)}
                        // @ts-expect-error
                        workspaceKey={this.props.workspaceKey}
                        isWebapp={true}
                    />
                </div>
            </div>
        )
    }
}

CreateChart.propTypes = {
    resetHeader: PropTypes.func,
    setPendingNewChart: PropTypes.func,
    secretKey: PropTypes.string.isRequired,
    workspaceKey: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    secretKey: state.user.secretKey,
    workspaceKey: state.context.workspace.key
})

const mapDispatchToProps = dispatch => ({
    resetHeader: () => dispatch(resetHeader()),
    setPendingNewChart: () => dispatch(setPendingNewChart())
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateChart)
