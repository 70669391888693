export class Either {

    constructor (leftValue, rightValue) {
        this.leftValue = leftValue
        this.rightValue = rightValue
    }

    static left(value) {
        return new Left(value)
    }

    static right(value) {
        return new Right(value)
    }

    fold(leftFn, rightFn) {}
}

class Left extends Either {

    constructor (value) {
        super(value, null)
    }

    fold (leftFn, rightFn) {
        leftFn(this.leftValue)
    }
}

class Right extends Either {

    constructor (value) {
        super(null, value)
    }

    fold (leftFn, rightFn) {
        rightFn(this.rightValue)
    }
}
