import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isSuperAdmin } from '../../reducers/user'
import lang from '../../utils/lang'
import FormButton from '../GUI/FormButton'
import { exposeErrors } from '../../utils/errors'
import { openModalDialog } from '../../actions/modalDialog'
import { cancelPendingPricingPlanChange, resubscribe, unsubscribe } from '../../services/companies.service'
import { getSubscriptionReport } from '../../services/reports.service'
import LoadingIndicator from '../GUI/LoadingIndicator'
import { notificationService } from '../../services/notification.service'

class SubscriptionReport extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            currentSubscription: null,
            willUnsubscribeIn: null,
            upcomingSubscription: null,
            nextSubscriptionStartsIn: null,
            fetched: false
        }
    }

    componentDidMount () {
        this.getSubscriptionInfo()
        this.setState({fetched: true})
        this.showMessage()
    }

    getSubscriptionInfo () {
        getSubscriptionReport().then(report => this.setState({
            currentSubscription: {
                pricingPlan: report.pricingPlan,
                isYearly: report.isYearly,
                period: report.period,
                committedMinimum: report.committedMinimum,
                usage: report.usage,
            },
            willUnsubscribeIn: report.willUnsubscribeIn,
            upcomingSubscription: report.upcomingSubscription,
            nextSubscriptionStartsIn: report.nextSubscriptionStartsIn
        }))
    }

    showMessage() {
        if (this.props.location && this.props.location.state && this.props.location.state.messageKey) {
            notificationService.info(lang.d(this.props.location.state.messageKey), lang.d(`${this.props.location.state.messageKey}_message`))
        }
    }

    render () {
        return <>
            <div className="title">Subscription</div>
            { this.state.currentSubscription
                ? <div className="subscription-facts">
                    { this.state.upcomingSubscription ? this.getSubscriptionFactsWithUpcomingSubscription() : this.getSubscriptionFacts() }
                </div>
                : (this.state.fetched ? this.noSubscription() : <LoadingIndicator/>)
            }
        </>
    }

    renderSubscriptionLink () {
        if (!isSuperAdmin(this.props.user)) {
            return <div className="description"><Link to="/account/change-plan" className="button">{lang.d('change_pricing_plan')}</Link></div>
        }
    }

    getSubscriptionFacts () {
        const facts = []

        facts.push(
            <div className="fact" key="plan">
                <div className="title">{lang.d('current_plan')}</div>
                <div className="value">{ this.pricingPlanName(this.state.currentSubscription) }</div>
                <div className="description">{ this.yearlyUsageDetails() }</div>
                { this.renderSubscriptionLink() }
            </div>
        )

        if (this.state.currentSubscription.isYearly) {
            const period = this.state.currentSubscription.period
            facts.push(
                <div className="fact" key="start">
                    <div className="title">{lang.d('started_on')}</div>
                    <div className="value">{ this.formatDate(this.toFirstDateOfMonth(period.start)) }</div>
                    <div className="separator" />
                    { this.renderRenewalOrExpiration() }
                </div>
            )
        }

        return facts
    }

    getSubscriptionFactsWithUpcomingSubscription () {
        const facts = []

        facts.push(
            <div className="fact" key="plan">
                <div className="title">{lang.d('current_plan')}</div>
                <div className="value">{ this.pricingPlanName(this.state.currentSubscription) }</div>
                <div className="separator" />
                <div className="title">{lang.d('started_on')}</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(this.state.currentSubscription.period.start)) }</div>
                <div className="description">{ this.yearlyUsageDetails() }</div>
                { this.renderSubscriptionLink() }
            </div>
        )

        const upcomingSubscription =  this.state.upcomingSubscription
        facts.push(
            <div className="fact">
                <div className="title">{lang.d('changing_to')}</div>
                <div className="value">{ this.pricingPlanName(upcomingSubscription) }</div>
                <div className="separator" />
                <div className="title">{lang.d('starting_on')}</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(upcomingSubscription.period.start)) }</div>
                <div className="separator" />
                <div className="title">{lang.d('renewing_on')}</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(upcomingSubscription.nextSubscriptionStartsIn)) }</div>
                { this.renderCancelPricingPlanChangeButton() }
            </div>
        )

        return facts
    }

    renderRenewalOrExpiration () {
        if (this.state.willUnsubscribeIn) {
            const unsubscribeDate = new Date(this.state.willUnsubscribeIn.year, this.state.willUnsubscribeIn.month, 0)
            return <>
                <div className="title">{lang.d('ends_on')}</div>
                <div className="value">{ this.formatDate(unsubscribeDate) }</div>
                { this.renderReEnableAutomaticRenewalButton() }
            </>
        } else {
            return <>
                <div className="title">{lang.d('renews_on')}</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(this.state.nextSubscriptionStartsIn)) }</div>
                { this.renderStopRenewalButton() }
            </>
        }
    }

    hasYearlyPlanWithRenewalDate () {
        return this.state.currentSubscription.isYearly && !this.state.willUnsubscribeIn
    }

    renderStopRenewalButton () {
        if (!this.hasYearlyPlanWithRenewalDate()) {
            return null
        }
        if (this.state.currentSubscription.pricingPlan.isEnterprisePlan) {
            return null
        }
        return <div className="action">
            <FormButton onClick={this.stopAutomaticRenewal.bind(this)}>{lang.d('stop_automatic_renewal')}</FormButton>
            <div className="hint">{lang.d('current_subscription_uninterrupted')}</div>
        </div>
    }

    stopAutomaticRenewal () {
        const companyId = this.props.companyId
        this.props.openModalDialog({
            title: lang.d('stop_automatic_renewal'),
            successMessage: lang.d('automatic_renewal_stopped'),
            onSubmit: async () => {
                await exposeErrors(unsubscribe(companyId)).then(() => this.getSubscriptionInfo())
            },
            settings: {
                acceptCaption: lang.d('stop_renewal'),
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {},
                    render: () => this.stopAutomaticRenewalInfo()
                }
            }
        })
        return () => true
    }

    stopAutomaticRenewalInfo () {
        return <div className="facts">
            <div className="fact">{lang.d('your_subscription_will_continue')} <strong>until {this.formatDate(this.calculateEndOfCurrentSubscription(this.state.currentSubscription.period))}</strong>.</div>
            <div className="fact">{lang.d('automatic_renewal_on')} {this.formatDate(this.calculateNextRenewalDate(this.state.currentSubscription.period))} {lang.d('will_not_happen')}.</div>
            <div className="fact">{lang.d('all_data_remains_after_unsubscribing')}</div>
        </div>
    }

    renderCancelPricingPlanChangeButton () {
        return <div className="action">
            <FormButton onClick={this.showCancelPendingPricingPlanChangeModal.bind(this)}>{lang.d('cancel_pricing_plan_change')}</FormButton>
            <div className="hint">{lang.d('current_subscription_uninterrupted')}</div>
        </div>
    }

    showCancelPendingPricingPlanChangeModal () {
        const companyId = this.props.companyId
        this.props.openModalDialog({
            title: lang.d('cancel_pricing_plan_change'),
            successMessage: lang.d('pricing_plan_change_cancelled'),
            onSubmit: async () => {
                await exposeErrors(cancelPendingPricingPlanChange(companyId)).then(() => this.getSubscriptionInfo())
            },
            settings: {
                acceptCaption: lang.d('confirm'),
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {},
                    render: () => <></>
                }
            }
        })
        return () => true
    }

    renderReEnableAutomaticRenewalButton () {
        if (this.state.willUnsubscribeIn === undefined) {
            return null
        }
        if (this.state.currentSubscription.pricingPlan.isEnterprisePlan) {
            return <p>{lang.d('automatic_renewal_contact')} <a href="mailto:sales@seats.io">sales@seats.io</a></p>
        } else {
            const nextRenewalDate = this.formatDate(this.calculateNextRenewalDate(this.state.currentSubscription.period))
            return <div className="action">
                <FormButton onClick={this.reEnableAutomaticRenewal.bind(this)}>{lang.d('re_enable_automatic_renewal')}</FormButton>
                <div className="hint">{lang.d('automatically_renew_on', {nextRenewalDate})}</div>
            </div>
        }
    }

    reEnableAutomaticRenewal () {
        const companyId = this.props.companyId
        this.props.openModalDialog({
            title: lang.d('re_enable_automatic_renewal'),
            successMessage: lang.d('automatic_renewal_re_enabled'),
            onSubmit: async () => {
                await exposeErrors(resubscribe(companyId)).then(() => this.getSubscriptionInfo())
            },
            settings: {
                acceptCaption: lang.d('re_enable_renewal'),
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {},
                    render: () => this.reEnableAutomaticRenewalInfo()
                }
            }
        })
        return () => true
    }

    reEnableAutomaticRenewalInfo () {
        return <div className="facts">
            <div className="fact">Your subscription will re-start <strong>from {this.formatDate(this.calculateNextRenewalDate(this.state.currentSubscription.period))}</strong>.</div>
            <div className="fact">{lang.d('current_subscription_uninterrupted')}</div>
        </div>
    }

    calculateNextRenewalDate (period) {
        const nextRenewalDate = new Date(period.end.year, period.end.month)
        return this.toFirstDateOfMonth({month: nextRenewalDate.getMonth() + 1, year: nextRenewalDate.getFullYear()})
    }

    calculateEndOfCurrentSubscription (period) {
        return this.toLastDateOfMonth({month: period.end.month, year: period.end.year})
    }

    toFirstDateOfMonth (month) {
        return new Date(month.year, month.month - 1)
    }

    toLastDateOfMonth (month) {
        return new Date(month.year, month.month, 0)
    }

    formatDate (month) {
        return format(month, 'd MMM yyyy')
    }

    noSubscription () {
        return <>
            <div>You don't have an active subscription</div>
            <Link to="/account/upgrade-now" className="Button">{ lang.d('upgrade') }</Link>
        </>
    }

    yearlyUsageDetails () {
        if (!this.state.currentSubscription.isYearly) {
            return null
        }
        if (this.state.currentSubscription.committedMinimum) {
            return <div>You've used <b>{this.state.currentSubscription.usage}</b> out of <b>{this.state.currentSubscription.committedMinimum}</b> committed seats.</div>
        }
        return <div>You've used <b>{this.state.currentSubscription.usage}</b> seats.</div>
    }

    pricingPlanName = (subscription) => {
        const name = subscription.pricingPlan.name || 'Custom'
        if (subscription.isYearly) {
            return `Yearly ${name}`
        } else {
            return `Monthly ${name}`
        }
    }
}

SubscriptionReport.propTypes = {
    user: PropTypes.object,
    openModalDialog: PropTypes.func.isRequired,
    companyId: PropTypes.number,
    location: PropTypes.object
}

const mapStateToProps = (state) => ({
    user: state.user,
    companyId: state.context.company.id
})

const mapDispatchToProps = dispatch => ({
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionReport)
