import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import lang from '../../utils/lang'
import format from 'date-fns/format'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import { getCompanies, getCompany, setPlan } from '../../actions/company'
import { Formik } from 'formik'
import FormButton from '../../components/GUI/FormButton'
import * as yup from 'yup'
import { CountryDropdown } from 'react-country-region-selector'
import { exposeErrors } from '../../utils/errors'
import { adminService } from '../../services/admin.service'
import { getRegions } from '../../domain/regions'

const months = [
    { value: '1', label: 'Jan' },
    { value: '2', label: 'Feb' },
    { value: '3', label: 'Mar' },
    { value: '4', label: 'Apr' },
    { value: '5', label: 'May' },
    { value: '6', label: 'Jun' },
    { value: '7', label: 'Jul' },
    { value: '8', label: 'Aug' },
    { value: '9', label: 'Sep' },
    { value: '10', label: 'Oct' },
    { value: '11', label: 'Nov' },
    { value: '12', label: 'Dec' }
]

const validationSchema = yup.object().shape({
    startYear: yup.number(),
    startMonth: yup.number(),
    country: yup.string(),
    pricingTier: yup.number(),
    isYearly: yup.boolean(),
    whitelabeled: yup.boolean(),
    region: yup.string()
})

const initialFilterValues = {
    country: '',
    startYear: undefined,
    startMonth: undefined,
    pricingTier: undefined,
    isYearly: undefined,
    whitelabeled: undefined,
    region: undefined
}

class Companies extends Component {
    constructor (props) {
        super(props)
        this.state = {
            list: null,
            pricingPlans: []
        }
        this.debouncedLoadData = debounce(this.loadData, 400)
    }

    renderHeaders () {
        return <>
            <th width="30%">{ lang.d('name') }</th>
            <th width="10%">{lang.d('created_on')}</th>
            <th width="30%">{lang.d('comment')}</th>
            <th width="10%">{lang.d('whitelabeled')}</th>
            <th width="10%">{lang.d('status')}</th>
        </>
    }

    async componentDidMount () {
        this.loadData(this.state.initialFilterValues)
        this.props.setHeader({
            showSearch: true,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [
                {
                    to: '/admin',
                    icon: 'icon-arrow-light-left',
                    string_key: 'admin',
                    className: 'soft-button'
                },
                {
                    to: '/admin/email-companies',
                    icon: 'icon-email',
                    string_key: 'email_companies',
                    className: 'soft-button'
                }
            ]
        })
        exposeErrors(adminService.getPricingPlans(), null, this.state)
            .then(pricingPlans => {
                const plans = this.formatPricingPlans(pricingPlans)
                this.setState({pricingPlans: plans})
            })
    }

    componentDidUpdate (prevProps) {
        if (this.props.searchValue !== prevProps.searchValue) {
            this.debouncedLoadData()
        }
    }

    formatPricingPlans (pricingPlans) {
        const uniquePlans = {}
        pricingPlans.filter(plan => plan.name.toLowerCase().indexOf("legacy") === -1 && plan.name.toLowerCase().indexOf("custom") === -1).forEach(plan => uniquePlans[plan.id] = plan.name)
        const plans = []
        for (const [key, value] of Object.entries(uniquePlans)) {
            plans.push({id: parseInt(key), name: value});
        }
        return plans.sort((a, b) => a.id - b.id)
    }

    async loadData (filterValues) {
        const values = filterValues ? filterValues : {}
        const companies = await this.props.getCompanies(this.props.searchValue, values.country, values.startYear, values.startMonth, values.pricingTier, values.isYearly, values.whitelabeled, values.region)
        this.setState({ list: companies })
    }

    renderRows () {
        return this.state.list.map((company) =>
            <tr key={company.id}>
                <td className="action-cell">
                    <Link to={`/admin/company/${company.region}/${company.id}`}><span>{company.name || lang.d('anonymous')}</span></Link>
                    {this.props.loggedInCompanyId !== company.id &&
                    <span title={lang.d('login_as_company')} onClick={() => this.switchToCompany(company.id, company.region)}>
                        <span className="icon icon-login"/>
                    </span>
                    }
                </td>
                <td>{company.createdOn ? format(company.createdOn, 'd MMM yyyy') : '-'}</td>
                <td ><div className="user-comment">{company.comment}</div></td>
                <td>{company.whitelabeled ? 'whitelabeled' : 'watermark shown'}</td>
                <td>{company.status}</td>
            </tr>
        )
    }

    renderFilters () {
        return <div className="company-filters">
            <Formik initialValues={initialFilterValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => this.loadData(values)}>
                {props =>
                    <form className="newStyle" onSubmit={props.handleSubmit}>
                        <div className="flex-wrapper">
                            <div className="form-field">
                                <div className="container">
                                    <span className="inline-field">
                                        <label>Country: </label>
                                        <CountryDropdown
                                            name="country"
                                            valueType="short"
                                            onChange={(val, e) => {
                                                props.handleChange(e)
                                            }}
                                            value={props.values.country}
                                            defaultOptionLabel={'Any'}
                                            className="country-dropdown"
                                        />
                                    </span>
                                    <span className="inline-field">
                                        <label>Pricing tier: </label>
                                        <select id="pricingTier" name="pricingTier" value={props.values.pricingTier} onChange={e => props.handleChange(e)}>
                                            <option key="pricing-plan-all" value={null}></option>
                                            {this.state.pricingPlans.map(plan => <option key={`pricing-tier-${plan.id}`} value={plan.id}>{plan.name}</option>)}
                                        </select>
                                    </span>
                                    <span className="inline-field">
                                        <label>Plan type: </label>
                                        <select id="isYearly" name="isYearly" value={props.values.isYearly} onChange={e => props.handleChange(e)}>
                                            <option key="plan-type-all" value={null}></option>
                                            <option key="plan-type-yearly" value="true">Yearly</option>
                                            <option key="plan-type-monthly" value="false">Monthly</option>
                                        </select>
                                    </span>
                                    <span className="inline-field">
                                        <label>Whitelabeling: </label>
                                        <select id="whitelabeled" name="whitelabeled" value={props.values.whitelabeled} onChange={e => props.handleChange(e)}>
                                            <option key="whitelabeling-both" value={null}></option>
                                            <option key="whitelabeling-whitelabeled" value="true">Whitelabeled</option>
                                            <option key="whitelabeling-watermarked" value="false">Watermarked</option>
                                        </select>
                                    </span>
                                    <span className="inline-field">
                                        <label>Start month: </label>
                                        <select id="startMonth" name="startMonth" value={props.values.startMonth} onChange={e => props.handleChange(e)}>
                                            <option key="start-month-all" value={null}></option>
                                            {months.map(month => <option key={`start-month-${month.value}`} value={month.value}>{month.label}</option>)}
                                        </select>
                                    </span>
                                    <span className="inline-field">
                                        <label>Start year: </label>
                                        <input id="startYear" name="startYear" type="number" min="2010" value={props.values.startYear} onChange={e => props.handleChange(e)}/>
                                    </span>
                                    <span className="inline-field">
                                        <label>Region: </label>
                                        <select id="region" name="region" value={props.values.whitelabeled} onChange={e => props.handleChange(e)}>
                                            <option key="region-all" value={null}></option>
                                            {getRegions().map(region => <option key={`region-${region.id}`} value={region.id}>{region.name}</option>)}
                                        </select>
                                    </span>
                                    <FormButton><span className="icon icon-loupe"></span></FormButton>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </Formik>
        </div>
    }

    async switchToCompany (id, region) {
        const company = await this.props.getCompany(id, region)
        this.props.switchToCompany(company)
        persistencyService.remove('charts-scroll')
        this.props.setExitPath(this.props.pathname)
        history.push('/')
    }

    render () {
        if (this.state.list) {
            return <div className="Companies">
                <div className="container">
                    {this.state.list && this.renderFilters()}
                    <div className="DataTable">
                        <table>
                            <thead>
                                <tr>
                                    {this.renderHeaders()}
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        } else {
            return <>
                {this.state.list && this.renderFilters()}
                <div className="empty-page-message">
                    <div className="title">{lang.d('no_results')}</div>
                </div>
            </>
        }
    }
}

Companies.propTypes = {
    setHeader: PropTypes.func,
    searchValue: PropTypes.string,
    switchToCompany: PropTypes.func,
    getCompanies: PropTypes.func,
    getCompany: PropTypes.func,
    loggedInCompanyId: PropTypes.number,
    pathname: PropTypes.string,
    setExitPath: PropTypes.func
}

const mapStateToProps = state => ({
    searchValue: state.search.companies.value,
    loggedInCompanyId: state.context.company.id,
    pathname: state.router.location.pathname
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    switchToCompany: company => {
        return dispatch({
            type: 'SWITCH_TO_DEFAULT_WORKSPACE_OF_COMPANY',
            company
        })
    },
    setExitPath: pathname => {
        return dispatch({
            type: 'SET_EXIT_PATH',
            pathname
        })
    },
    setPlan: (id, regionId, value) => dispatch(setPlan(id, regionId, value)),
    getCompanies: (searchValue, country, startYear, startMonth, pricingTier, isYearly, whitelabeled, region) => dispatch(getCompanies(searchValue, country, startYear, startMonth, pricingTier, isYearly, whitelabeled, region)),
    getCompany: (id, region) => dispatch(getCompany(id, region))
})

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
