import React from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import lang from '../../utils/lang'
import { getMonthlyPlans, getYearlyPlans, pricingPlanDescription } from '../Account/PricingPlans'
import LoadingIndicator from '../../components/GUI/LoadingIndicator'
import { getCompanyUpgradeInfo, postImmediateUpgradeAccountInfo } from '../../services/companies.service'
import { setHasChosenSubscriptionPlan, updateUser } from '../../actions/user'
import PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import { CountryDropdown } from 'react-country-region-selector'
import { checkVAT, countries } from 'jsvat'
import { SteppedProgress } from '../../components/GUI/SteppedProgress'
import FormButton from '../../components/GUI/FormButton'
import { PlanDetails } from '../../components/GUI/PlanDetails'
import { isEeaCountry } from '../../utils/countries'
import { notificationService } from '../../services/notification.service'

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    addressStreet: yup.string().required(),
    addressZipcode: yup.string().required(),
    addressCity: yup.string().required(),
    addressState: yup.string(),
    addressCountryCode: yup.string().required(),
    vat: yup.string().notRequired(),
    billingEmail: yup.string().email().required(),
    subscriptionPlan: yup.string().required(),
    agreedToTerms: yup.boolean()
})

class ImmediateUpgrade extends React.Component {

    constructor (props) {
        super(props)
        this.updateUser = this.updateUser.bind(this)
        this.state = {
            initialValues: {
                name: '',
                addressStreet: '',
                addressZipcode: '',
                addressCity: '',
                addressState: '',
                addressCountryCode: '',
                vat: '',
                billingEmail: '',
                subscriptionPlan: '',
                agreedToTerms: false
            },
            showVat: false
        }
    }

    async componentDidMount () {
        this.props.setHeader({
            leftItems: [{
                to: '/account',
                icon: 'icon-arrow-light-left',
                string_key: 'account',
                className: 'soft-button'
            }]
        })
        const companyInfo = await getCompanyUpgradeInfo(this.props.companyId)
        const existingValues = {}
        existingValues.name = this.companyDetailsKnown(companyInfo) ? this.valueOrDefault(companyInfo.companyName) : ''
        existingValues.billingEmail = this.valueOrDefault(companyInfo.billingEmail)
        existingValues.agreedToTerms = companyInfo.agreedToTerms
        existingValues.subscriptionPlan = companyInfo.subscriptionPlanName
        if (companyInfo.billingInfo) {
            existingValues.vat = this.valueOrDefault(companyInfo.billingInfo.vat)
            if (companyInfo.billingInfo.address) {
                existingValues.addressStreet = this.valueOrDefault(companyInfo.billingInfo.address.street)
                existingValues.addressZipcode = this.valueOrDefault(companyInfo.billingInfo.address.zipcode)
                existingValues.addressCity = this.valueOrDefault(companyInfo.billingInfo.address.city)
                existingValues.addressState = this.valueOrDefault(companyInfo.billingInfo.address.state)
                existingValues.addressCountryCode = this.valueOrDefault(companyInfo.billingInfo.address.countryCode)
            }
        }
        this.setState({initialValues : existingValues})
    }

    companyDetailsKnown (companyInfo) {
        return companyInfo.billingInfo && companyInfo.billingInfo.address && companyInfo.billingInfo.address.countryCode
    }

    valueOrDefault (value) {
        return value ? value : ''
    }

    updateUser (company) {
        this.props.updateUser({ company })
    }

    renderCustomerDetails () {
        const steps = [
            {  name: lang.d('upgrade-account-description'), status: 'current' },
            {  name: 'Payment details', status: 'notStarted' },
        ]
        const errorClassName = (props, fieldName) => props.errors[fieldName] ? 'error' : null
        return <Formik initialValues={this.state.initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            validate={(values) => {
                const errors = {};
                if (values.vat) {
                    const country = countries.filter(c => c.codes.indexOf(values.addressCountryCode) >= 0);
                    if (country.length > 0) {
                        const vatCheckResult = checkVAT(values.vat, country)
                        if (!vatCheckResult.isValid) {
                            errors.vat = 'Invalid VAT number'
                        }
                    }
                }
                return errors
            }}
            onSubmit={async (values, { setStatus }) => {
                try {
                    const userAndCompany = await postImmediateUpgradeAccountInfo(this.props.companyId, values)
                    this.updateUser(userAndCompany.company)
                    this.props.notifyOfChosenSubscriptionPlan()
                    this.props.history.push('/account/payment-details')
                } catch (e) {
                    notificationService.error(lang.d('error'), e.response.data.messages[0])
                    setStatus(null)
                }
            }}>
            {props =>
                <form className={`newStyle ${props.isSubmitting && 'submitting'} ${props.status}`}
                    onSubmit={props.handleSubmit}>
                    <div className="display-title">{lang.d('upgrade-account')}</div>
                    <SteppedProgress steps={steps} />
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="name">{lang.d('business-name-and-legal-form')}</label>
                        </div>
                        <div className="field">
                            <input type="text" placeholder={lang.d('business-name-and-legal-form-placeholder')}
                                id="name" name="name" className={errorClassName(props, 'name')}
                                onChange={props.handleChange} value={props.values.name}/>
                        </div>
                    </div>
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="addressStreet">{lang.d('legal-address')}</label>
                        </div>
                        <div className="field">
                            <input type="text" id="addressStreet" name="addressStreet" onChange={props.handleChange}
                                value={props.values.addressStreet} placeholder={lang.d('street-address')}
                                className={errorClassName(props, 'addressStreet')}/>
                            <div className="flex-wrapper">
                                <input type="text" name="addressZipcode" onChange={props.handleChange}
                                    value={props.values.addressZipcode} placeholder={lang.d('postal-zipcode')}
                                    className={errorClassName(props, 'addressZipcode')}/>
                                <input type="text" name="addressCity" onChange={props.handleChange}
                                    value={props.values.addressCity} placeholder={lang.d('city')}
                                    className={errorClassName(props, 'addressCity')}/>
                            </div>
                            <div className="flex-wrapper">
                                <input type="text" name="addressState" onChange={props.handleChange}
                                    value={props.values.addressState} placeholder={lang.d('state-province')}
                                    className={errorClassName(props, 'addressState')}/>
                                <CountryDropdown
                                    name="addressCountryCode"
                                    valueType="short"
                                    defaultOptionLabel={lang.d('country')}
                                    onChange={(val, e) => {
                                        props.handleChange(e)
                                        this.setState({showVat: isEeaCountry(val)})
                                    }}
                                    value={props.values.addressCountryCode}
                                    className={errorClassName(props, 'addressCountryCode')}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.showVat && <div className="form-field">
                        <div className="description">
                            <label htmlFor="vat">{lang.d('vat-number')}</label>
                            <div className="hint">
                                <strong>{lang.d('optional')}.</strong>{ props.values.addressCountryCode && isEeaCountry(props.values.addressCountryCode) && <>{lang.d('vat-number-hint')} {lang.d('vat-notice')}</>}
                            </div>
                        </div>
                        <div className="field">
                            <input type="text" id="vat" name="vat" onChange={props.handleChange}
                                value={props.values.vat} className={errorClassName(props, 'vat')}/>
                        </div>
                    </div>}
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="billingEmail">{lang.d('contact-email')}</label>
                            <div className="hint">{lang.d('contact-email-hint')}</div>
                        </div>
                        <div className="field">
                            <input type="text" id="billingEmail" name="billingEmail" onChange={props.handleChange}
                                value={props.values.billingEmail} className={errorClassName(props, 'billingEmail')}/>
                        </div>
                    </div>
                    <div className="form-field">
                        <div className="description flex">
                            <label htmlFor="subscriptionPlan">{lang.d('pricing-plan')}</label>
                            <a href="https://www.seats.io/pricing" className="pricing-plan-link hint external-link" target="_blank"
                                rel="noopener noreferrer">{lang.d('pricing-plan-link')}</a>
                        </div>
                        <div className="field">
                            <select id="subscriptionPlan" name="subscriptionPlan" onChange={props.handleChange}
                                value={props.values.subscriptionPlan}
                                className={errorClassName(props, 'subscriptionPlan')}>
                                <optgroup label="Yearly plans">
                                    {getYearlyPlans().map(plan => <option value={plan.id} key={plan.id}>{plan.displayName}</option>)}
                                </optgroup>
                                <optgroup label="Monthly plans">
                                    {getMonthlyPlans().map(plan => <option value={plan.id} key={plan.id}>{plan.displayName}</option>)}
                                </optgroup>
                                <option value="" disabled>{lang.d('please_select')}</option>
                            </select>
                            {props.values.subscriptionPlan && <PlanDetails
                                title={lang.d('selectedPlan', { plan: lang.d(props.values.subscriptionPlan) })}
                                description={pricingPlanDescription(props.values.subscriptionPlan)}
                            />}
                        </div>
                    </div>
                    <div className="termsAgreement">
                        <input type="checkbox" id="agreedToTerms" name="agreedToTerms" checked={props.values.agreedToTerms} value={props.values.agreedToTerms} onChange={props.handleChange} />
                        <span className="checkbox-label">
                            <label htmlFor="agreedToTerms">{lang.d('read-and-agree-seatsio-terms-of-use')}</label>
                        </span>
                        <a href="https://www.seats.io/legal/terms" target="_blank" className="external-link terms"
                            rel="noopener noreferrer">{lang.d('read-terms-of-use')}</a>
                    </div>
                    <hr className="divider" />
                    <div className="form-actions">
                        {props.isSubmitting
                            ? <LoadingIndicator/>
                            :  <FormButton className="action" disabled={!props.values.agreedToTerms}>{lang.d('proceedToPayment')}</FormButton>
                        }
                    </div>
                </form> }
        </Formik>
    }

    render () {
        return <div className="Upgrade">
            <div className="sectioned-page">
                <div className="sectioned-page-container">
                    <div className="section">
                        { this.renderCustomerDetails() }
                    </div>
                </div>
            </div>
        </div>
    }
}

ImmediateUpgrade.propTypes = {
    notifyOfChosenSubscriptionPlan: PropTypes.func.isRequired,
    setHeader: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    companyId: PropTypes.number
}

const mapStateToProps = (state) => ({
    companyId: state.context.company.id
})

const mapDispatchToProps = dispatch => ({
    notifyOfChosenSubscriptionPlan: () => dispatch(setHasChosenSubscriptionPlan()),
    setHeader: payload => dispatch(setHeader(payload)),
    updateUser: payload => dispatch(updateUser(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImmediateUpgrade)
