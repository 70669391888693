const defaultState = {
    submitEnabled: true,
    settings: {},
    closed: true,
    accepted: false,
    done: false,
    doneMessage: null,
    doneIcon: 'check',
    isSubmitting: false,
    steps: []
}

const modalWizard = (state = defaultState, action) => {
    switch (action.type) {
    case 'MODAL_WIZARD/SET_SUBMIT_ENABLED':
        return {
            ...state,
            submitEnabled: action.payload
        }
    case 'MODAL_WIZARD/SET_SUBMITTING':
        return {
            ...state,
            isSubmitting: action.payload
        }
    case 'MODAL_WIZARD/ACCEPTED':
        return {
            ...state,
            accepted: true
        }
    case 'MODAL_WIZARD/ON_CHANGE':
        return {
            ...state,
            ...action.payload
        }
    case 'MODAL_WIZARD/ON_SUCCESS':
        return {
            ...state,
            ...action.payload,
            done: true,
            accepted: true
        }
    case 'MODAL_WIZARD/OPEN':
        return {
            ...state,
            ...action.payload,
            closed: false
        }
    case 'MODAL_WIZARD/SET_SETTINGS':
        return {
            ...state,
            ...action.payload
        }
    case 'MODAL_WIZARD/CLOSE':
        return {
            ...state,
            closed: true
        }
    case 'MODAL_WIZARD/CLEAR': return defaultState
    default:
        return state
    }
}

export default modalWizard
