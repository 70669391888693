import { SeatsioClient } from 'seatsio'

export class WebappSeatsioClient extends SeatsioClient {

    constructor(region, secretKey, workspaceKey, companyId) {
        super(region, secretKey, workspaceKey, WebappSeatsioClient.extraHeaders(companyId));
        this.webhooks = new Webhooks(this.client);
    }

    static extraHeaders(companyId) {
        const headers = {}
        headers['X-Company-ID'] = companyId
        return headers
    }
}

export class Webhooks {

    constructor(client) {
        this.client = client;
    }

    async generatePortalUrl() {
        let result = await this.client.post('/webhooks/actions/generate-portal-url')
        return result.data.url
    }

    async enable() {
        await this.client.post('/webhooks/actions/enable')
    }

    async disable() {
        await this.client.post('/webhooks/actions/disable')
    }
}
