import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    clearModalWizard,
    closeWizard,
    modalWizardAccepted,
    onModalWizardSuccess,
    setModalWizardSubmitting
} from '../actions/modalWizard'
import { MODAL_WIZARD_CLEAR_TIMEOUT } from '../constants/modalWizard'
import ModalWizard from "./GUI/ModalWizard";

class ModalWizardContainer extends React.Component {
    constructor (props) {
        super(props)
        this.successCloseTimeout = null
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    componentDidMount () {
        window.addEventListener('keydown', this.onKeyDown)
    }

    componentWillUnmount () {
        window.removeEventListener('keydown', this.onKeyDown)
    }

    onKeyDown (e) {
        if (e.key === 'Escape' && !this.props.modalWizard.closed) {
            this.onCancel()
        }
    }

    isVisible () {
        const { title, message, settings } = this.props.modalWizard
        return Boolean(title || message || Object.keys(settings).length > 0)
    }

    isWaiting () {
        return this.props.modalWizard.settings.waitOnAccept && this.props.modalWizard.accepted
    }

    isWaitingCompleted () {
        return this.props.modalWizard.done
    }

    async onAccept () {
        const { settings } = this.props.modalWizard
        if (this.isWaiting() && !this.isWaitingCompleted()) {
            return false
        }
        try {
            if (settings.onAccept) {
                await settings.onAccept()
            }
            if (settings.waitOnAccept) {
                this.props.dispatch(modalWizardAccepted())
            } else {
                this.props.dispatch(clearModalWizard())
            }
        } catch (error) {
            if (settings.onAcceptFailed) settings.onAcceptFailed(error)
        }
    }

    close () {
        clearTimeout(this.successCloseTimeout)
        this.props.dispatch(closeWizard())
        setTimeout(() => this.props.dispatch(clearModalWizard()), MODAL_WIZARD_CLEAR_TIMEOUT)
    }

    onCancel () {
        if ((this.isWaiting() && !this.isWaitingCompleted()) || this.props.modalWizard.isSubmitting || this.props.modalWizard.accepted) {
            return false
        }
        if (this.props.modalWizard.settings.onCancel) this.props.modalWizard.settings.onCancel()
        this.close()
    }

    showSuccessMessageAndClose () {
        if (this.props.modalWizard.successMessage) {
            this.props.dispatch(onModalWizardSuccess({ done: true, doneMessage: this.props.modalWizard.successMessage, doneIcon: this.props.modalWizard.doneIcon }))
            this.successCloseTimeout = setTimeout(() => this.close(), 1300 + (this.props.modalWizard.successMessage ? 400 : 0))
        } else {
            this.close()
        }
    }

    setModalSubmitting (value) {
        this.props.dispatch(setModalWizardSubmitting(value))
    }

    render () {
        if (!this.props.modalWizard.newModal) return null

        return <ModalWizard
            settings={this.props.modalWizard.settings}
            steps={this.props.modalWizard.steps}
            closed={this.props.modalWizard.closed}
            doneMessage={this.props.modalWizard.doneMessage}
            doneIcon={this.props.modalWizard.doneIcon}
            submitEnabled={this.props.modalWizard.submitEnabled}
            visible={this.isVisible()}
            waiting={this.isWaiting()}
            isWaitingCompleted={this.isWaitingCompleted()}
            onCancel={() => this.onCancel()}
            showSuccessMessageAndClose={this.showSuccessMessageAndClose.bind(this)}
            close={this.close.bind(this)}
            setModalSubmitting={this.setModalSubmitting.bind(this)}
        />
    }
}

ModalWizardContainer.propTypes = {
    modalWizard: PropTypes.object,
    dispatch: PropTypes.func
}

const mapStateToProps = store => ({
    modalWizard: store.modalWizard
})

export default connect(mapStateToProps)(ModalWizardContainer)
