import React, { useCallback, useEffect, useRef, useState } from 'react'
import { setHeader } from '../../actions/header'
import { useDispatch } from 'react-redux'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import OptionSwitcher from '../../components/GUI/OptionSwitcher'
import {
    getCompany,
    getPricingPlans,
    getTimeline,
    setActive,
    setComment,
    setCompanyFields,
    setFeatureFlag,
    setWhiteLabel
} from '../../actions/company'
import TextInput from '../../components/GUI/TextInput'
import { SubscriptionEvents } from './SubscriptionEvents'
import { sortBy } from 'lodash'
import { Region } from './Region'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import DropDown from '../../components/GUI/DropDown'
import { Timeline } from './Timeline'
import { toEuroString } from './utils'
import { adminService } from '../../services/admin.service'
import { notificationService } from '../../services/notification.service'

function Company (props) {
    const [fetched, setFetched] = useState(false)
    const [loadingComment, setLoadingComment] = useState(false)
    const [commentUpdateSuccess, setCommentUpdateSuccess] = useState(false)
    const [commentUpdateFailed, setCommentUpdateFailed] = useState(false)
    const [form, setForm] = useState(null)
    const [pricingPlans, setPricingPlans] = useState(null)
    const [timeline, setTimeline] = useState(null)
    const [loading, setLoading] = useState(null)
    const [reverseTimeline, setReverseTimeline] = useState(true)

    const dispatch = useDispatch()
    const commentArea = useRef()
    const companyId = props.match.params.id
    const companyRegionId = props.match.params.region

    let animateCommentArea = null

    const retrieveCompanyAndPricingPlansAndTimeline = useCallback(async () => {
        try {
            const company = await dispatch(getCompany(companyId, companyRegionId))
            setForm(company)

            const pricingPlans = await dispatch(getPricingPlans())
            setPricingPlans(pricingPlans)

            const timeline = await dispatch(getTimeline(companyId, companyRegionId))
            setTimeline(timeline)

            setFetched(true)
        } catch {
        } finally {
            setFetched(true)
        }
    }, [dispatch, companyId, companyRegionId])

    useEffect(() => {
        async function init () {
            dispatch(setHeader({
                leftItems: [{
                    to: '/admin/companies',
                    icon: 'icon-arrow-light-left',
                    string_key: 'companies',
                    className: 'soft-button'
                }]
            }))

            await retrieveCompanyAndPricingPlansAndTimeline()
        }

        init()
    }, [dispatch, retrieveCompanyAndPricingPlansAndTimeline])

    const postComment = async () => {
        try {
            setLoadingComment(true)
            await dispatch(setComment(companyId, companyRegionId, commentArea.current.value))
            setCommentUpdateSuccess(true)
        } catch (e) {
            commentArea.current.value = form.comment
            setCommentUpdateFailed(true)
        } finally {
            setLoadingComment(false)
            await retrieveCompanyAndPricingPlansAndTimeline()
            if (animateCommentArea) clearTimeout(animateCommentArea)
            animateCommentArea = setTimeout(() => {
                setCommentUpdateSuccess(false)
                setCommentUpdateFailed(false)
            }, 2000)
        }
    }

    const triggerUpdate = async (field, data) => {
        try {
            await dispatch(setCompanyFields(companyId, companyRegionId, data))
            await retrieveCompanyAndPricingPlansAndTimeline()
            setLoading(null)
        } catch {
            return null
        }
    }

    const switchToCompany = async () => {
        const pathname = window.location.pathname
        dispatch({
            type: 'SWITCH_TO_DEFAULT_WORKSPACE_OF_COMPANY',
            company: form
        })
        persistencyService.remove('charts-scroll')
        dispatch({
            type: 'SET_EXIT_PATH',
            pathname
        })
        history.push('/')
    }

    function isInt (n) {
        // eslint-disable-next-line eqeqeq
        return parseInt(n) == n
    }

    function toOptionalInt (s) {
        if (s === '') {
            return null
        } else if (isInt(s)) {
            return parseInt(s)
        }
        return s
    }

    const updateCompanyInfo = async () => {
        setLoading('companyInfo')
        if (form.activeIsDirty) {
            dispatch(setActive(companyId, companyRegionId, form.active.toString()))
        }
        await triggerUpdate(companyId, {
            name: form.name,
            apiRateLimit: toOptionalInt(form.apiRateLimit),
            zohoCompanyId: form.zohoCompanyId,
            stripeCustomerId: form.stripeCustomerId
        })
    }

    const superadminFeatureFlagSwitches = {
        'USE_SAFE_RENDERER_COLORS': { 'type': 'boolean' },
        'REMOVE_COORDINATES_FROM_PUBLIC_CHART_JSON': { 'type': 'boolean' },
        'RELOAD_OBJECT_STATUSES_INTERVAL_IN_SECONDS': { 'type': 'number' },
        'LIVE_SUPPORT_LIMITED_TO_COMPANY_ADMINS': { 'type': 'boolean' },
        'FOR_SALE_MODE': {
            'type': 'enum',
            'values': [
                {value: 'RATE_LIMITED', label: lang.d('FOR_SALE_MODE_RATE_LIMITED')},
                {value: 'UNLIMITED_BILLABLE', label: lang.d('FOR_SALE_MODE_UNLIMITED_BILLABLE')},
                {value: 'UNLIMITED_NOT_BILLABLE', label: lang.d('FOR_SALE_MODE_UNLIMITED_NOT_BILLABLE')}
            ]
        },
        'ALLOW_PUBLIC_HOLD_TOKEN_CREATION': {'type': 'boolean'},
        'OPEN_LATEST_DRAWING_BY_DEFAULT_IN_DESIGNER': {'type': 'boolean'},
        'MAX_HOLDS_PER_TOKEN': {'type': 'number'},
        'CAN_USE_RACE_TRACKS': {'type': 'boolean'},
        'ALLOW_UNLIMITED_TRIAL_PERIOD': {'type': 'boolean'},
        'OBFUSCATE_OBJECT_STATUSES': {'type': 'boolean'},
        'PAYMENT_WINDOW_IN_DAYS': {'type': 'number'},
        'USE_POPOVER': {'type': 'boolean'},
        'ALLOW_AUTOMATIC_SUBSCRIPTION': {'type':'boolean'},
        'USE_SEATSIO_MESSAGING': {'type': 'boolean'},
    }

    const updateAdminControls = async () => {
        setLoading('adminControls')
        await dispatch(setWhiteLabel(companyId, companyRegionId, form.whitelabeled))
        await Promise.all(Object.keys(superadminFeatureFlagSwitches).map(featureFlagName => dispatch(setFeatureFlag(companyId, companyRegionId, featureFlagName, form.superadminFeatureFlags[featureFlagName]))))
        await triggerUpdate(companyId, { definitionOfUse: form.definitionOfUse })
    }

    const superadminFeatureFlagControls = () => {
        return Object.keys(superadminFeatureFlagSwitches).map(featureFlagName => {
            if (superadminFeatureFlagSwitches[featureFlagName].type === 'boolean') {
                if (form.superadminFeatureFlags[featureFlagName] !== undefined) {
                    return <>
                        <div className="item toggleable">
                            <div className="caption">{lang.d(featureFlagName)}</div>
                            <div className="value">
                                <OptionSwitcher
                                    key={featureFlagName + '_control'}
                                    value={form.superadminFeatureFlags[featureFlagName].toString()}
                                    options={{
                                        true: 'true',
                                        false: 'false'
                                    }}
                                    onChange={value => {
                                        let newForm = {
                                            ...form,
                                        }
                                        newForm.superadminFeatureFlags[featureFlagName] = value === 'true'
                                        setForm(newForm)
                                    }}
                                />
                            </div>
                        </div>
                    </>
                } else {
                    console.error('Missing value: ' + featureFlagName)
                    return <>
                        <div>Missing value: {featureFlagName}</div>
                    </>
                }
            } else if (superadminFeatureFlagSwitches[featureFlagName].type === 'number') {
                return <>
                    <div className="item toggleable">
                        <div className="caption">{lang.d(featureFlagName)}</div>
                        <div className="value">
                            <TextInput
                                for={featureFlagName}
                                value={form.superadminFeatureFlags[featureFlagName]?.toString()}
                                placeholder=""
                                onChange={value => {
                                    let newForm = {
                                        ...form,
                                    }
                                    newForm.superadminFeatureFlags[featureFlagName] = value
                                    setForm(newForm)
                                }}
                            />
                        </div>
                    </div>
                </>
            } else if (superadminFeatureFlagSwitches[featureFlagName].type === 'enum') {
                return <>
                    <div className="item toggleable">
                        <div className="caption">{lang.d(featureFlagName)}</div>
                        <div className="value">
                            <DropDown
                                name={featureFlagName + '_control'}
                                list={superadminFeatureFlagSwitches[featureFlagName].values}
                                onChange={e => {
                                    let newForm = {
                                        ...form,
                                    }
                                    newForm.superadminFeatureFlags[featureFlagName] = e.target.value
                                    setForm(newForm)
                                }}
                                selectedIndex={superadminFeatureFlagSwitches[featureFlagName].values.findIndex(item => item.value === form.superadminFeatureFlags[featureFlagName].toString())}
                            />
                        </div>
                    </div>
                </>
            } else {
                return null
            }
        })
    }

    const confirmAccount = async () => {
        setLoading('companyInfo')
        await adminService.confirmEmail(form.initialUser.confirmationCode, companyRegionId)
        await retrieveCompanyAndPricingPlansAndTimeline()
        setLoading(null)
    }

    const describePricingPlan = (pricingPlanId, isYearly) => {
        const pricingPlan = pricingPlans.filter(plan => plan.id === pricingPlanId && plan.isYearly === isYearly)
        return pricingPlan.length > 0 ? `${pricingPlan[0].isYearly === true ? 'Yearly' : 'Monthly'} ${pricingPlan[0].name}` : 'Unknown'
    }

    const timelineData = (eventData) => {
        const formatValue = (key, value) => {
            switch (key) {
            case 'amount':
            case 'amountReceived':
                return toEuroString(value)
            default:
                return value.toString()
            }
        }

        const rows = []
        for (const key in eventData) {
            if (key === 'pricingPlanId') {
                rows.push(<tr><td>{lang.d('pricing_plan')}</td><td className="timeline-data-value">{describePricingPlan(eventData.pricingPlanId, eventData.isYearly)}</td></tr>)
            } else if (key === 'isYearly') {
                // do nothing
            } else {
                const value = formatValue(key, eventData[key])
                rows.push(<tr><td>{lang.d(key)}</td><td className="timeline-data-value">{value}</td></tr>)
            }
        }
        return rows
    }

    const timelineDate = (eventTimestamp) => {
        return new Intl.DateTimeFormat('en-gb', {
            month: '2-digit', day: '2-digit', year: 'numeric',
            hour: '2-digit', minute: '2-digit',
            hour12: false,
            timeZone: 'Europe/Brussels'
        }).format(new Date(eventTimestamp))
    }

    function syncWithStripe() {
        adminService.syncWithStripe(companyId, companyRegionId)
            .then(result => result.fold(err => notificationService.error(lang.d('something_went_wrong'), err), value => notificationService.info(lang.d('success'), value)))
    }

    if (fetched && form) {
        return <div className="Company">
            <div className="container">
                <div className={`company-info section ${loading === 'companyInfo' && 'loading'}`}>
                    <div className="title">Company info
                        <span title={lang.d('login_as', { companyName: form.name })}
                            onClick={() => switchToCompany(companyId, companyRegionId)}>
                            <span className="icon icon-login small-left-and-right-space"/>
                        </span>
                    </div>
                    <div className="item">
                        { form.initialUser && form.initialUser.status === "UNCONFIRMED" &&
                        <div>
                            <button className="FormButton btn" onClick={confirmAccount}>Account not confirmed. Confirm this account!</button>
                        </div>
                        }
                    </div>
                    <div className="item toggleable">
                        <div className="caption">{lang.d('name')}</div>
                        <div className="value">
                            <TextInput
                                for="name"
                                value={form.name}
                                onChange={name => setForm({
                                    ...form,
                                    name
                                })}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <label className="caption">{lang.d('id')}</label>
                        <div className="value">{companyId}</div>
                    </div>
                    <div className="item toggleable">
                        <label className="caption">{lang.d('status')}</label>
                        <div className="value">
                            <OptionSwitcher
                                value={form.active.toString()}
                                options={{
                                    true: lang.d('active'),
                                    false: lang.d('inactive')
                                }}
                                onChange={active => setForm({
                                    ...form,
                                    active: active === 'true',
                                    activeIsDirty: true
                                })}
                            />
                        </div>
                    </div>
                    <div className="item toggleable">
                        <div className="caption">{lang.d('api_rate_limit')}</div>
                        <div className="value">
                            <TextInput
                                for="apiRateLimit"
                                value={form.apiRateLimit?.toString()}
                                placeholder="50"
                                onChange={apiRateLimit => setForm({
                                    ...form,
                                    apiRateLimit
                                })}
                            />
                        </div>
                    </div>
                    <div className="item toggleable">
                        {!form.zohoCompanyLink && <div className="caption">{lang.d('zoho_company_id')}</div>}
                        {form.zohoCompanyLink && <div className="caption">
                            <a href={form.zohoCompanyLink}
                                className="external-link"
                                target="_blank">{lang.d('zoho_company_id')}</a>
                        </div>}
                        <div className="value">
                            <TextInput
                                for="zohoCompanyId"
                                value={form.zohoCompanyId?.toString()}
                                onChange={zohoCompanyId => setForm({
                                    ...form,
                                    zohoCompanyId
                                })}
                            />
                        </div>
                    </div>
                    <div className="item toggleable">
                        {!form.stripeCustomerLink && <div className="caption">{lang.d('stripe_customer_id')}</div>}
                        {form.stripeCustomerLink && <div className="caption">
                            <a href={form.stripeCustomerLink}
                                className="external-link"
                                target="_blank">{lang.d('stripe_customer_id')}</a>
                        </div>}
                        <div className="value">
                            <TextInput
                                for="stripeCustomerId"
                                value={form.stripeCustomerId?.toString()}
                                onChange={stripeCustomerId => setForm({
                                    ...form,
                                    stripeCustomerId
                                })}
                                action={syncWithStripe}
                                actionIcon="refresh"
                                actionTitle={lang.d('sync_with_stripe')}
                                actionEnabled={form.stripeCustomerId != null}
                            />
                        </div>
                    </div>
                    <button className="FormButton btn" onClick={updateCompanyInfo}>Save</button>
                </div>

                <Region
                    companyRegionId={companyRegionId}
                    companyId={form.id}
                    companyStatus={form.status}
                />

                <div className={`user-controls section ${loading === 'adminControls' && 'loading'}`}>
                    <div className="title">Admin controls</div>
                    <OptionSwitcher
                        value={form.definitionOfUse}
                        options={{
                            FIRST_BOOKING_OR_SELECTION: lang.d('first_status_change_or_selection'),
                            FIRST_BOOKING: lang.d('first_status_change')
                        }}
                        onChange={definitionOfUse => setForm({
                            ...form,
                            definitionOfUse
                        })}
                    />
                    <OptionSwitcher
                        value={form.whitelabeled.toString()}
                        options={{
                            true: lang.d('whitelabeled'),
                            false: lang.d('watermark')
                        }}
                        onChange={whitelabeled => setForm({
                            ...form,
                            whitelabeled: whitelabeled === 'true'
                        })}
                    />
                    {superadminFeatureFlagControls()}
                    <button className="FormButton btn" onClick={updateAdminControls}>Save</button>
                </div>
                <div className="section">
                    <SubscriptionEvents
                        subscriptionEvents={sortBy(form.subscriptionEvents, ['year', 'month'])}
                        pricingPlans={pricingPlans}
                        companyId={form.id}
                        companyRegionId={companyRegionId}
                        onUpdated={subscriptionEvents => setForm({
                            ...form,
                            subscriptionEvents
                        })}>
                    </SubscriptionEvents>
                    {timeline && (
                        <Timeline
                            entries={timeline}
                            reverseTimeline={reverseTimeline}
                            getPricingPlanName={(pricingPlanId, isYearly) => describePricingPlan(pricingPlanId, isYearly)}
                            getEventDetails={(eventData) => timelineData(eventData)}
                        />
                    )}
                </div>

                <div className="comment section">
                    <div className="title">{lang.d('comments')}</div>
                    <textarea name="comment"
                        className={`comment-area ${loadingComment && 'loading'} ${commentUpdateSuccess && 'success'} ${commentUpdateFailed && 'failed'}`}
                        ref={commentArea} defaultValue={form.comment} spellCheck="false"/>
                    <button className="FormButton btn" onClick={() => postComment()}>Save</button>
                </div>
            </div>
        </div>
    } else if (fetched && !form) {
        return (
            <div className="empty-page-message">
                <div className="title">{lang.d('no_results')}</div>
            </div>
        )
    }
    return null
}

Company.propTypes = {
    setHeader: PropTypes.func,
    setActive: PropTypes.func,
    getCompany: PropTypes.func,
    setComment: PropTypes.func,
    setWhiteLabel: PropTypes.func,
    setZohoCompanyId: PropTypes.func,
    setStripeCustomerId: PropTypes.func,
    setName: PropTypes.func,
    setPlan: PropTypes.func,
    match: PropTypes.object.isRequired
}

export default Company
