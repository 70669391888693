import lang from '../../utils/lang'
import Input from "../../components/Input";
import React from "react";
import * as PropTypes from "prop-types";

class TwoFactorAuthenticationCode extends React.Component {

    render () {
        const {
            errors,
            touched,
            values,
            isSubmitting,
            status,
            handleChange
        } = this.props

        return <div className="form">
            <div className="form-field wide">
                <Input id="code" name="code" type="text" value={values.code} placeholder={lang.d('authenticator_code')} onChange={handleChange} disabled={isSubmitting || status === 'submitted'} autoFocus />
                { errors.code && touched.code &&
                    <div className="errors">
                        { errors.code && <div className="error">{errors.code}</div> }
                    </div>
                }
            </div>
        </div>
    }
}

TwoFactorAuthenticationCode.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    isSubmitting: PropTypes.bool,
    status: PropTypes.string,
    handleChange: PropTypes.func,
}

export {
    TwoFactorAuthenticationCode
}
