const dictionary = {
    id: 'ID',
    email: 'Email',
    active: 'Active',
    work_email: 'Work email',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_new_password: 'Confirm New Password',
    password: 'Password',
    confirm_password: 'Confirm password',
    change_password: 'Change Password',
    choose_password: 'Choose Password',
    name: 'Name',
    workspace_secret_key: 'Secret workspace key',
    admin_key: 'Admin key',
    anonymous: 'Anonymous',
    seating_chart: 'Seating chart',
    seating_charts: 'Seating charts',
    usage: 'Usage',
    settings: 'Settings',
    admin: 'Admin',
    error: 'Error',
    search: 'Search',
    search_events: 'Search events',
    signup: 'Sign up',
    login: 'Log in',
    logout: 'Log out',
    return_to_original_account: 'Return to original account',
    required: 'Required',
    invalid_email: 'Please provide a valid email',
    password_mismatch: "Passwords don't match",
    invalid_password: 'Password should be at least 8 characters long',
    email_password_required: 'Email and password are required',
    loading: 'Loading',
    x_events: '%{formatted_smart_count} event |||| %{formatted_smart_count} events',
    no_events: 'No events',
    no_results: 'No results',
    reset_password: 'Reset password',
    offline: 'Offline',
    cancel: 'Cancel',
    accept: 'Accept',
    numeric_input_scrubber_hint: 'Click & drag to change value',
    edit_seating_chart: 'Edit chart',
    actions: 'Actions',
    see_all_events: 'See all events',
    view_archive: 'View archive',
    archive_chart: 'Archive chart',
    duplicate_chart: 'Duplicate chart',
    published: 'Published',
    unpublished_changes: 'Unpublished changes',
    publish: 'Publish',
    discard_changes: 'Discard changes',
    view_published_version: 'View published version',
    publish_changes: 'Publish changes',
    draft_version_has_validation_warnings: 'Draft version of this chart has unresolved validation warnings',
    unresolved_validation: 'This chart has unresolved validation warnings or errors',
    validation_warnings: 'Validation warnings',
    validation_errors: 'Validation errors',
    draft_version_has_validation_errors: 'Draft version of this chart has unresolved errors that prevent it from being published',
    copy_chart_with_validation_issues: 'This chart has warnings or errors. Are you sure you would like to copy?',
    publish_changes_desc: 'Are you sure you want to publish these changes on this seating chart? This cannot be undone.',
    discard_changes_desc: 'Are you sure you want to discard unpublished changes on this seating chart? This cannot be undone.',
    instructions_sent: 'Instructions sent',
    reset_password_check_email: 'We sent an email to your address with instructions to reset your password.',
    delete: 'Delete',
    delete_event_confirmation: 'Are you sure you want to delete this event?',
    delete_event: 'Delete event',
    create_event: 'Create event',
    events: 'Events',
    confirm: 'Confirm',
    created_on: 'Created on',
    booked: 'Booked',
    free: 'Free',
    capacity: 'Capacity',
    edit_key: 'Edit key',
    summary: 'Summary',
    statuses: 'Statuses',
    for_sale: 'For sale',
    not_for_sale: 'Not for sale',
    available: 'Available',
    unavailable: 'Unavailable',
    tables: 'Tables',
    channels: 'Channels',
    categories: 'Categories',
    custom_status: 'Custom status',
    summary_manager_hint: 'View current occupancy.',
    statuses_manager_hint: 'Book or unbook objects.',
    for_sale_manager_hint: 'Make some objects available or unavailable for sale.',
    tables_manager_hint: 'Make tables bookable as a whole or by seat.',
    channel_manager_hint: 'Assign objects to channels.',
    categories_manager_hint: 'Override object categories for this event.',
    click_to_close: 'Click to close',
    create_chart: 'New seating chart',
    security_notice: 'Important security notice',
    security_notice_secret_key: 'Keep this key secret. It can be used to to book or release seats, and to link seating charts to events. Never use it for in-browser calls to seats.io.',
    security_notice_admin_key: 'Keep this key secret. It\'s a master key that carries privileges to access all Seats.io API endpoints, on any one of your workspaces. Never use it inside the browser.',
    request_new_secret_key: 'Request a new secret key',
    new_secret_key: 'New secret key',
    new_secret_key_are_you_sure: 'Are you sure you want to request a new secret key?',
    yes_im_sure: 'Yes, I\'m sure',
    workspace_key_extra_info: 'Use the public workspace key for all in-browser REST calls to seats.io. Seats.io uses it to find out which user a chart belongs to.',
    important_notice: 'Important notice',
    notice_enable_drafts: 'We strongly recommend having this feature enabled, as this will prevent accidental editing of published seating charts.',
    drafts_for_published_charts: 'Drafts for published charts',
    drafts_for_published_charts_usage: 'When enabled, changes made to a published chart will be saved as a draft to be published later, instead of being published immediately.',
    enabled: 'Enabled',
    disabled: 'Disabled',
    hint: 'Hint',
    hold_period_description: 'You can pass in the "session" parameter to your rendered charts so that seats are automatically held upon selection.',
    read_more: 'Read more',
    hold_period: 'Hold period',
    hold_period_usage: 'Time period after which the session expires. At that point, held objects will be automatically released again.',
    use_company_setting: 'Use company setting',
    use_workspace_setting: 'Override',
    minutes_short: 'min',
    hold_period_in_minutes: 'Hold Period in Minutes',
    types_of_validation: 'Types of validation',
    validation_off: 'Off',
    validation_off_desc: 'Validation is ignored.',
    validation_warning: 'Warning',
    validation_warning_desc: 'If validation fails, it will be shown as a warning in the designer at the chart inspector and publish dialog.',
    validation_error: 'Error',
    validation_error_desc: 'If validation fails, it will prevent the chart from being published or have an event created from it.',
    chart_validation: 'Chart validation',
    chart_validation_desc: 'Configure for which validations you would like to get warnings, errors that prevent publishing, or ignore completely.',
    objects_without_label: 'Objects without a label',
    objects_without_label_desc: 'Validates that charts do not contain unlabeled seats.',
    duplicate_label: 'Duplicate labels',
    duplicate_label_desc: 'Validates that charts do not contain two objects with the same label (in example: two seats labeled A-1 would fail validation).',
    uncategorized_objects: 'Uncategorized objects',
    uncategorized_objects_desc: 'Validates that charts do not contain objects that are not categorized.',
    missing_focal_point: 'Missing focal point',
    missing_focal_point_desc: 'Validates that charts have a focal point. Without one, the Best Available selection algorithm is disabled.',
    multiple_objects_per_category: 'Multiple object types per category',
    multiple_objects_per_category_desc: 'Validates that charts do not have two different object types using the same category (in example: a seat and a booth using the same category would fail validation)',
    quick_create_event: 'Quick Create Event',
    empty_archive: 'Your archive is empty',
    event_deleted: 'Event deleted',
    failed_to_contact_server: 'Failed to contact server',
    discard: 'Discard',
    discarded: 'Discarded',
    all_seating_charts: 'All seating charts',
    date: 'Date',
    object: 'Object',
    status: 'Status',
    quantity: 'Quantity',
    quantity_short: 'Qty',
    order: 'Order',
    holdtoken: 'Holdtoken',
    origin: 'Origin',
    displayedAs: 'Displayed as',
    status_changes: 'Status changes',
    seating_chart_archived: 'Seating chart was archived',
    seating_chart_restored: 'Seating chart was restored',
    restore: 'Restore',
    seating_chart_duplicated: 'Seating chart was duplicated',
    event_exists: 'An event with that key already exists',
    invalid_key_warning: 'The event key should only contain numbers, letters and dashes',
    empty_key_warning: 'The event key should contain at least one number or letter',
    empty_name_warning: 'A non-empty name is required',
    name_too_long_warning: 'Names must be %{maxChars} or less',
    remove_name: 'Remove name',
    remove_name_confirmation: 'Are you sure you want to remove the name?',
    name_removed: 'Name removed',
    tag_exists: 'Tag already assigned',
    empty_tag: 'Tag cannot be empty',
    invalid_tag: 'Invalid tag',
    welcome_to_seats: 'Welcome to Seats.io',
    get_started_create_seating_chart: 'Get started by creating a new seating chart',
    archive_empty: 'Archive is empty',
    report_empty: 'You haven\'t used any seats yet.',
    report_failed: 'We are having issues getting your reports',
    archive_empty_description: 'Archived charts will show up here',
    report_empty_description: 'A detailed report of your usage will show up here',
    report_failed_description: 'Please try again in a few minutes',
    search_status_changes_hint: 'Search by object label',
    CONTAINS: 'contains',
    MATCHES: 'exact match',
    BEGINS_WITH: 'begins with',
    ENDS_WITH: 'ends with',
    account_unconfirmed: 'You\'re almost there! Please check your inbox for the account confirmation mail.',
    account_unconfirmed_title: 'Account not yet confirmed',
    account_inactive: 'Your account is inactive. Please contact sales@seats.io.',
    account_inactive_title: 'Account inactive',
    email_confirmation_error: 'There was a problem confirming your e-mail address',
    email_confirmation_error_title: 'Confirmation failed',
    resend_confirmation_email: 'Resend e-mail',
    confirmation_email_resent_successfully_title: 'E-mail resent successfully',
    confirmation_email_resent_successfully: 'Please check your inbox',
    splash_screen_general_error_modal_dialog_title: 'Whoops',
    splash_screen_general_error_modal_dialog_message: 'We\'re sorry, but something went wrong',
    get_support: 'Get support',
    go_to_home: 'Go home',
    splash_screen_not_found_modal_dialog_title: '404',
    splash_screen_not_found_modal_dialog_message: 'This is not the page you\'re looking for...',
    back: 'Back',
    chart_key: 'Chart key',
    tags: 'Tags',
    preview_link: 'Public preview link',
    create_workspace: 'Create workspace',
    create_workspace_description: 'Create a new workspace, which contains its own charts and events.',
    create: 'Create',
    edit_workspace_name: 'Edit workspace name',
    edit_name: 'Edit name',
    update: 'Update',
    workspace_created: 'Workspace created',
    workspace_updated: 'Workspace updated',
    event_created: 'Event created',
    optional: 'Optional',
    none: 'None',
    slow_response_title: 'Hold on tight...',
    slow_response: 'The connection to our server seems to be slow',
    edit: 'Edit',
    deactivate: 'Deactivate',
    deactivated: 'Deactivated',
    activate: 'Activate',
    activated: 'Activated',
    wrong_credentials: 'Wrong credentials',
    user_not_found: 'Could not find this user',
    failed_server_connection: 'Failed to connect to server',
    create_event_validation_errors: 'Cannot create an event for an invalid chart. Please fix all chart errors first. Do you want to edit the chart now to fix the validation errors?',
    publish_chart_with_validation_errors: 'Draft version of this chart contains validation errors and cannot be published. Please fix all chart errors first. Do you want to edit the chart now to fix the validation errors?',
    fix_in_designer: 'Fix in designer',
    invalid_chart: 'Invalid chart',
    login_as_user: 'Log in as user',
    login_as_company: 'Log in as Company',
    login_as: 'Log in as %{companyName}',
    invite_team_member: 'Invite team member',
    invite_team_member_email_msg: 'Your team member will receive a confirmation email with a link to choose a password.',
    team_member_invited: 'Team member invited',
    billable_status_changes_or_selections: 'Billable status changes or selections',
    monthly_usage: 'Monthly usage',
    event_not_found: 'Could not find an event with this key',
    chart_not_found: 'Could not find a chart with this key',
    billable_status_changes: 'Billable status changes',
    x_billable_status_changes: '%{formatted_smart_count} billable status change |||| %{formatted_smart_count} billable status changes',
    x_first_status_changes: '%{formatted_smart_count} first status change |||| %{formatted_smart_count} first status changes',
    x_billable_status_changes_or_selections: '%{formatted_smart_count} billable status change or selection |||| %{formatted_smart_count} billable status changes or selections',
    copy_event_key: 'Copy event key',
    event_key_copied: 'Event key copied to clipboard',
    event_key: 'Event key',
    first_status_changes: 'First status changes',
    first_status_change: 'First status change',
    first_status_change_or_selection: 'First status change or selection',
    x_first_status_changes_or_selections: 'First status changes or selections',
    first_selections: 'First selections',
    total_selections: 'Total selections',
    search_seating_charts: 'Search seating charts',
    empty_event_report: 'Empty Report',
    empty_event_report_description: 'Please try again in a few minutes',
    all_reports: 'All Reports',
    workspace_list_all_workspaces: 'All workspaces',
    edit_event_key_message: 'Event key must contain letters and numbers only.',
    edit_event_key: 'Edit event key',
    eventKey_updated: 'Event key changed',
    add_event_name: 'Add event name',
    edit_event_name: 'Edit event name',
    event_name_updated: 'Event name changed',
    edit_season_name: 'Edit season name',
    season_name_updated: 'Season name changed',
    change_key: 'Change key',
    add_name: 'Add name',
    change_name: 'Change name',
    intercom_message_help_webapp_error: 'Help! The webapp encountered an error.',
    last_25_months: 'Last 25 months',
    last_12_months: 'Last 12 months',
    all_months: 'All months',
    webapp_version: 'Seats.io web app version',
    webapp_version_usage: 'You are currently using the new seats.io web app. Use the button below to revert to the previous version.',
    VALIDATE_TOO_MANY_PLACES: 'Capacity too big',
    VALIDATE_DUPLICATE_LABELS: 'Duplicate objects',
    VALIDATE_OBJECTS_WITHOUT_CATEGORIES: 'Uncategorized objects',
    VALIDATE_OBJECT_TYPES_PER_CATEGORY: 'Categories applied to multiple object types',
    VALIDATE_UNLABELED_OBJECTS: 'Unlabelled objects',
    VALIDATE_FOCAL_POINT: 'No focal point set',
    VALIDATE_EMPTY_FLOOR: 'Empty floor',
    VALIDATE_NO_OBJECTS: 'No objects',
    duplicated: 'Duplicated',
    total_used_seats: 'Total used seats',
    used_seats_per_day: 'Used seats per day',
    report_generated_at: 'Report generated %{distance} ago',
    no_report_for_month: 'There\'s no report for this month',
    copy_chart: 'Copy Chart',
    email_chartKey_required: 'Email and Chart Key are required',
    expected_usage_month: 'Expected used seats',
    usage_details: 'Details',
    x_renderings: '%{formatted_smart_count} rendering |||| %{formatted_smart_count} renderings',
    x_first_selections: '%{formatted_smart_count} first selection |||| %{formatted_smart_count} first selections',
    copy_to_workspace: 'Copy to Workspace...',
    copy: 'Copy',
    pick_one: 'Pick one...',
    copied: 'Copied',
    comment: 'Comment',
    comments: 'Comments',
    whitelabeled: 'Whitelabeled',
    remarks: 'Remarks',
    parent_account: 'Parent account',
    admin_account: 'Admin account',
    close: 'Close',
    usage_detail: 'Usage Detail',
    used_object_statuses: 'Used object statuses',
    used_statuses: 'Used Statuses',
    filtered_out_statuses: 'Filtered out Statuses',
    without_status_change: 'Without status change',
    ga: 'General Admission',
    non_ga: 'Non General Admission',
    total: 'Total',
    live_support: 'Live support',
    documentation: 'Documentation',
    help_center: 'Help center',
    STATUS_CHANGE_ORIGIN_TYPE_API_CALL: 'API call',
    STATUS_CHANGE_ORIGIN_TYPE_HOLD_EXPIRED: 'Hold expired',
    STATUS_CHANGE_ORIGIN_TYPE_HOLD_RELEASED_BY_USER: 'Hold released by end user',
    STATUS_CHANGE_ORIGIN_TYPE_HELD_BY_USER: 'Hold by end user',
    'n/a': 'N/A',
    empty_floor: 'Empty Floor',
    empty_floor_desc: 'Validates that charts do not have empty floors',
    no_objects: 'No objects',
    no_objects_desc: 'Validates that charts have bookable objects (e.g. seats, tables, etc)',
    copy_charts_from_email: 'Copy charts from user',
    copy_charts_to_email: 'Copy charts to user',
    copy_charts_tag: 'Tag to copy (leave blank to copy all charts)',
    copy_charts: 'Copy Multiple Charts',
    copy_charts_can_be_many_warning: 'Warning: this may copy a lot of seating charts, and there is no undo. Please verify this is correct.',
    tag_to_copy: 'tag to copy',
    no_tag_to_copy_specified: 'No tag was specified. You will copy ALL charts from %{from} to %{to}',
    tag_to_copy_specified: 'You will copy ONLY charts from %{from} to %{to} that are tagged with `%{tag}`',
    confirm_copy_charts: 'Yes, do this!',
    copy_single_chart: 'Copy a single chart from one account to the other',
    copy_multiple_charts: 'Copy multiple charts from one account to the other',
    from: 'From',
    to: 'To',
    tag: 'tag',
    from_email_required: 'From email is required',
    to_email_required: 'To email is required',
    users: 'Users',
    inactive: 'Inactive',
    watermark: 'Watermark',
    whats_new: "What's new",
    see_changelog: 'See changelog',
    add_tag: 'Add tag',
    account: 'Account',
    'upgrade-account': 'Upgrade your account',
    'upgrade-account-description': 'Please provide the information requested below to start a paid subscription.',
    'business-name-and-legal-form': 'Name and legal form of the business',
    'business-name-and-legal-form-placeholder': 'e.g. ACME Ltd',
    'legal-address': 'Legal address',
    'legal-address-hint': 'Street and number, postal/zip code, city, state (if applicable), country.',
    'vat-number': 'VAT number',
    'vat-notice': 'Note that if you are inside the EEA (not Belgium) and don’t provide a valid VAT number, we will have to charge you Belgian VAT. If you are in Belgium, we will charge VAT.',
    'vat-number-hint': 'Provide a valid VAT number if located in the European Economic Area.',
    'contact-email': 'Contact email',
    'contact-email-hint': 'Email address that manages the account - invoices and other relevant communication will be sent to this address.',
    'read-and-agree-seatsio-terms-of-use': 'I have read and agree with the seats.io terms of use.',
    'read-terms-of-use': 'Read terms of use',
    'i-legally-commit': 'Please note that by clicking the “place order” button below you legally commit to pay the amounts set out in the pricing plan you select, and you confirm that you have the legal capacity to commit the business in this way.',
    'pricing-plan': 'Pricing plan',
    'pricing-plan-link': 'Compare all plans',
    'subscription': 'Subscription',
    'street-address': 'Street address',
    'postal-zipcode': 'Postal or zip code',
    city: 'City',
    'state-province': 'State or province',
    country: 'Country',
    upgrade: 'Upgrade',
    upgrade_requested: 'Thank you!',
    upgrade_requested_message: 'You\'ll receive an email confirming your requested change.',
    'upgrade-plan': 'Upgrade Plan',
    'change-plan': 'Change Plan',
    webhooks: 'Webhooks',
    definition_of_use: 'Definition of Use',
    companies: 'Companies',
    company: 'Company',
    billing_email: 'Billing Email',
    vat: 'VAT',
    state: 'State',
    street: 'Street',
    zipcode: 'Zip Code',
    subscription_plan: 'Subscription Plan',
    YEARLY_SILVER: 'Yearly Silver',
    YEARLY_GOLD: 'Yearly Gold',
    YEARLY_DIAMOND: 'Yearly Diamond',
    YEARLY_PLATINUM: 'Yearly Platinum',
    YEARLY_MITHRIL: 'Yearly Mithril',
    MONTHLY_GOLD: 'Monthly Gold',
    MONTHLY_DIAMOND: 'Monthly Diamond',
    MONTHLY_PLATINUM: 'Monthly Platinum',
    MONTHLY_MITHRIL: 'Monthly Mithril',
    title: 'Title',
    for_sale_rate_limit: 'For Sale Rate Limit',
    api_rate_limit: 'API Rate Limit',
    zoho_company_id: 'Zoho company ID',
    stripe_customer_id: 'Stripe customer ID',
    yes: 'Yes',
    no: 'No',
    USER_STATUS_INVITATION_PENDING: 'Invitation pending',
    USER_STATUS_ACTIVE: 'Active',
    USER_STATUS_INACTIVE: 'Inactive',
    USER_STATUS_UNCONFIRMED: 'Unconfirmed',
    team: 'Team',
    invite: 'Invite',
    team_members: 'Team members',
    workspaces: 'Workspaces',
    untitled: 'Untitled',
    workspace_key: 'Public workspace key',
    workspace_empty: 'No workspaces',
    switch_to_workspace: 'Switch to workspace',
    pending_invitations: 'Pending invitations',
    cancel_invitation: 'Cancel invitation to %{email}',
    cancel_invitation_message: 'You can always invite them again',
    cancel_invitation_confirm: 'Cancel invitation',
    invitation_canceled: 'The Invitation was cancelled',
    all_workspaces: 'All workspaces...',
    all_workspaces_for_member_hint: 'This team member has access to the following workspaces',
    'company-settings': 'Company Settings',
    'user-settings': 'User Settings',
    'workspace-settings': 'Workspace Settings',
    'workspaces-description': 'Workspaces are collections of charts and events to which different users within a company can have access to.',
    'learn-more': 'Learn more',
    current: 'Current',
    'production-short': 'Prod',
    test: 'Test',
    password_short: 'Please chooose a password at least 8 characters long.',
    password_changed: 'Password changed successfully.',
    at_least_one_workspace: 'Please select at least one workplace.',
    invalid_role: 'Please select user role.',
    NON_ADMIN: 'Non admin',
    COMPANY_ADMIN: 'Company admin',
    SUPER_ADMIN: 'SUPER admin',
    role: 'Role',
    show_all: 'Show all',
    show_more: 'Show more',
    and_x_more: 'and %{count} more...',
    'non-admin': 'Non-admin',
    edit_workspaces: 'Edit workspaces',
    select_workspaces: 'Select workspaces for team member',
    workspaces_saved: 'Workspaces saved',
    grant_access: 'Grant access',
    manage_team_member: 'Manage team member',
    apply_changes: 'Apply changes',
    applied: 'Applied',
    activate_user: 'Activate user',
    deactivate_user: 'Deactivate user',
    production: 'Production',
    deactivate_user_confirmation: 'Are you sure you want to deactivate %{user}?',
    user_deactivated: 'User is now inactive',
    test_workspace_hint: 'You will not be billed for used seats in test workspaces. Charts are watermarked.',
    production_workspace_hint_active_subscription: 'You will be billed for used seats in production workspaces.',
    production_workspace_hint_no_active_subscription: 'You will be billed for used seats in production workspaces (when you have an active subscription).',
    grant_access_to_workspaces: 'Grant access to workspaces...',
    invite_as: 'Invite as...',
    workspace_key_required: 'Workspace key is required',
    chart_key_required: 'Chart key is required',
    copy_to_admin: 'Copy to my account',
    nevermind: 'Nevermind',
    copied_to_admin_account: 'Seating chart copied to your account',
    default: 'Default',
    default_workspace: 'Default workspace',
    default_workspace_notice: 'What is default workspace?',
    default_workspace_notice_description: 'The workspace that is used for API calls, when you pass in an admin key but not a workspace key.',
    invitation_cancelled: 'This invitation is no longer valid.',
    go_to_log_in: 'Go to log in',
    what_is_workspace_key: 'What is the public key?',
    edit_email: 'Edit Email',
    email_updated: 'Email Updated',
    regenerate_company_admin_key: 'Regenerate admin key',
    regenerate_company_admin_key_confirmation: 'Are you sure you want to regenerate the admin key? This cannot be undone.',
    company_admin_key_regenerated: 'The admin key has been regenerated',
    regenerate_workspace_secret_key: 'Regenerate secret key',
    regenerate_workspace_secret_key_confirmation: 'Are you sure you want to regenerate the secret key for workspace "%{workspace}"? This cannot be undone.',
    workspace_secret_key_regenerated: 'The workspace secret key has been regenerated',
    regenerate: 'Regenerate',
    activate_workspace: 'Activate workspace',
    deactivate_workspace: 'Deactivate workspace',
    deactivate_workspace_confirmation: 'Are you sure you want to deactivate %{workspace}?',
    workspace_deactivated: 'Workspace deactivated',
    make_default_workspace: 'Make default workspace',
    make_default_workspace_confirmation: 'Are you sure you want to make this workspace the default workspace?',
    make_default: 'Make default',
    default_workspace_changed: 'Default workspace changed',
    event_details: 'Event details',
    renderer_settings: 'Renderer Settings',
    renderer_settings_desc_1: 'This section contains the default rendering settings, that will be applied when rendering any chart from any of your workspaces.',
    renderer_settings_desc_2: 'These settings can be still be overridden when rendering individual floor plans, via the rendering configuration options in javascript.',
    multiSelect_enabled: 'Multi-select enabled',
    show_full_screen_button: 'Show fullscreen button',
    public_preview_show_row_labels: 'Show row labels',
    public_preview_show_object_labels: 'Show seat, booth and table labels',
    server_region: 'Server region',
    server_region_hint: 'Select a region',
    server_region_help: 'Choose the closest Seats.io instance to your location to minimize network latency.',
    api_url: 'API URL',
    chartjs_url: 'chart.js URL',
    company_region_notice_1: 'We store your data (e.g. charts and events) in this region. The closer it is to your server, the faster Seats.io works.',
    company_region_notice_2: 'Migrating to an other region is possible, but not without some downtime.',
    short_hold_tokens: 'Short hold token keys',
    public_hold_token_creation: 'Public hold token creation',
    inactive_workspaces: 'Inactive workspaces',
    active_workspaces: 'Active workspaces',
    workspace_activated: 'Workspace has been activated',
    used_seats: 'Used seats',
    x_used_seats: '%{formatted_smart_count} used seat |||| %{formatted_smart_count} used seats',
    no_inactive_workspaces: 'There are no inactive workspaces',
    pricing_plan_begin_notice: 'Your new pricing plan will apply from 1st of next month.',
    change_pricing_plan_legal_notice: 'Please note that by clicking the “Confirm” button below you legally commit to pay the amounts set out in the pricing plan you select, and you confirm that you have the legal capacity to commit the business in this way.',
    please_select: 'Please select...',
    reset_password_error: 'Something went wrong. Please reset your password again.',
    temporarily_held: 'temporarily held',
    seasons: 'Seasons',
    missing: 'Missing',
    switched_to_book_by_seat_info: 'The status of this table changed when it was still bookable as a whole, but it\'s now bookable by seat',
    switched_to_book_whole_table_info: 'The status of this seat changed when the table was still bookable by seat, but the table is now bookable as a whole',
    renamed_or_deleted_info: 'This object was either removed from the chart, or its label changed',
    x_partial_seasons: '%{formatted_smart_count} partial season |||| %{formatted_smart_count} partial seasons',
    create_season: 'Create season',
    create_partial_season: 'Create partial season',
    create_event_in_season: 'Create event in season',
    manage_events: 'Manage events',
    manage_events_in_partial_season: 'Manage events in partial season',
    events_updated: 'Events updated',
    delete_season: 'Delete season',
    delete_season_confirmation: 'Are you sure you want to delete this season?',
    season_deleted: 'Season deleted',
    usage_reason: 'Used because',
    x_usage_reason_selected: '%{formatted_smart_count} selection |||| %{formatted_smart_count} selections',
    x_usage_reason_status_changed: '%{formatted_smart_count} status change |||| %{formatted_smart_count} status changes',
    x_usage_reason_assigned_to_channel: '%{formatted_smart_count} channel assignment |||| %{formatted_smart_count} channel assignments',
    use_new_renderer_appearance: 'Use new renderer appearance',
    cant_change_setting_company_level_priority: 'Cannnot change setting while it is enabled at the company level.',
    new_appearance_description: 'The new appearance brings a modern look, fluid transitions and clearer visual language to the Renderer and Event Manager.',
    new_appearance: 'New appearance',
    mark_as_in_the_past: 'Mark as in the past',
    mark_event_as_in_the_past_confirmation: 'Are you sure you want to mark this event as in the past? This operation cannot be undone.',
    event_is_in_the_past: 'Event is in the past',
    move_to_new_chart_copy_short: 'Detach event',
    move_to_new_chart_copy: 'Detach event into new chart',
    move_to_new_chart_copy_confirmation: 'Are you sure you want to detach this event and move it to a new copy of this chart? This operation cannot be undone.',
    move_to_new_chart_copy_done: 'Event moved',
    log_in_as_company_through_chart_key: 'Log in as company through chart key',
    find_chart: 'Find chart',
    REMOVE_COORDINATES_FROM_PUBLIC_CHART_JSON: 'Remove coordinates from public chart JSON',
    RELOAD_OBJECT_STATUSES_INTERVAL_IN_SECONDS: 'Poll object statuses every X seconds (a value = ably disabled, no value = no polling & use ably instead)',
    USE_SAFE_RENDERER_COLORS: 'Use safe (contrasting) colors in new renderer design',
    LIVE_SUPPORT_LIMITED_TO_COMPANY_ADMINS: 'Live support only available for company admins',
    FOR_SALE_MODE: 'For sale mode',
    FOR_SALE_MODE_RATE_LIMITED: 'Rate limited',
    FOR_SALE_MODE_UNLIMITED_BILLABLE: 'Unlimited (billable)',
    FOR_SALE_MODE_UNLIMITED_NOT_BILLABLE: 'Unlimited (not billable)',
    ALLOW_PUBLIC_HOLD_TOKEN_CREATION: 'Allow public hold token creation',
    OPEN_LATEST_DRAWING_BY_DEFAULT_IN_DESIGNER: 'Open latest drawing by default in embedded designer',
    MAX_HOLDS_PER_TOKEN: 'Maximum number of holds per token',
    CAN_USE_RACE_TRACKS: 'Can use race tracks',
    ALLOW_UNLIMITED_TRIAL_PERIOD: 'Allow an unlimited trial period',
    OBFUSCATE_OBJECT_STATUSES: 'Obfuscate object statuses',
    PAYMENT_WINDOW_IN_DAYS: 'Payment window in days',
    USE_POPOVER: 'Use popover',
    USE_SEATSIO_MESSAGING: 'Use seatsio-messaging (not Ably)',
    ALLOW_AUTOMATIC_SUBSCRIPTION: 'Allow automatic subscription',
    max_holds_per_token: 'Maximum number of holds per token',
    max_holds_per_token_usage: 'The maximum number of places or seats that can be held with a single hold token. When attempting to hold more than this number of places with the same hold token, the hold will fail.',
    no_name: 'No name',
    invoices: 'Invoices',
    amount: 'Amount',
    invoice_number: 'Invoice number',
    download: 'Download',
    view_invoice: 'View invoice',
    view_pay_invoice: 'View/pay invoice',
    downloading_invoice: 'Downloading invoice %{invoice_number}',
    downloading: 'Downloading',
    due_date: 'Due date',
    add_payment_method: 'Add payment method',
    unable_to_add_payment_method: 'It is currently not possible to update your payment details.',
    please_try_again_later: 'Please try again later.',
    payment_method_stored_in_stripe: 'Your billing details will be stored securely in Stripe for future billing.',
    payment_method_added: 'Payment method added',
    payment_method_added_message: 'Your payment method has been added and is now the default payment method.',
    your_subscription_will_be_active_in_a_few_seconds: 'Success, thank you!',
    your_subscription_will_be_active_in_a_few_seconds_message: 'Your subscription will be active within a few minutes',
    continue: 'Continue',
    details_will_be_stored_for_future_billing: 'Your details will be stored securely in Stripe for future billing',
    no_payment_will_be_taken: 'No payment will be taken at this stage',
    complete_upgrade: 'Complete upgrade',
    with_access_to: 'With access to',
    some_workspaces_user: 'Some workspaces',
    all_workspaces_user: 'All workspaces',
    createdOnAsc: 'By date created (oldest first)',
    nameOrKeyAsc: 'By name (ascending)',
    nameOrKeyDesc: 'By name (descending)',
    general: 'General',
    'chart-validation': 'Chart Validation',
    renderer: 'Renderer',
    'hold-tokens': 'Hold Tokens',
    security: 'Security',
    advanced: 'Advanced',
    vat_number_required: 'A VAT number is required for this country',
    invalid_country_code: 'This country is not supported',
    seasonal_events: 'seasonal events',
    no_match: `No %{type} match "%{searchTerm}"`,
    timeline: 'Timeline',
    oldestFirst: 'Oldest first',
    newestFirst: 'Newest first',
    year: 'Year',
    month: 'Month',
    type: 'Type',
    isYearly: 'Is yearly?',
    pricingPlanId: 'Pricing plan ID',
    invoiceId: 'Invoice ID',
    amountReceived: 'Amount received',
    brand: 'Brand',
    last4: 'Last 4 digits',
    bankCode: 'Bank code',
    branchCode: 'Branch code',
    system_event: 'System event',
    COMPANY_CREATED: 'Company created',
    EMAIL_CONFIRMED: 'Email confirmed',
    SUBSCRIPTION_REQUESTED: 'Subscription requested',
    SUBSCRIBED: 'Subscribed',
    SUBSCRIPTION_EVENT_ADDED: 'Subscription event added',
    SUBSCRIPTION_EVENT_DELETED: 'Subscription event deleted',
    SUBSCRIPTION_EVENT_UPDATED: 'Subscription event updated',
    WHITELABELLED: 'Whitelabelled',
    UNWHITELABELLED: 'Unwhitelabelled',
    PAYMENT_METHOD_ATTACHED: 'Payment method attached',
    PAYMENT_SUCCEEDED: 'Payment succeeded',
    PAYMENT_FAILED: 'Payment failed',
    PRICING_PLAN_CHANGED: 'Pricing plan changed',
    UNSUBSCRIBED: 'Unsubscribed',
    RESUBSCRIBED: 'Resubscribed',
    TRIAL_PERIOD_EXPIRED: 'Trial period expired',
    ACTIVATED: 'Activated',
    DEACTIVATED: 'Deactivated',
    pricing_plan: 'Pricing plan',
    selectedPlan: 'Selected plan: %{plan}',
    completePaymentDetails: 'Complete your payment details',
    proceedToPayment: 'Proceed to payment',
    prepaidPlanDetails: 'This plan is billed annually and you will be charged €%{amount} when the order is completed.',
    monthlyBilledPlanDetails: 'Your card will not be charged at this time, monthly plans are charged the following month.',
    placeOrder: 'Place order',
    paymentDetails: 'Payment details',
    paymentDetailsDescription: 'Please provide your preferred payment method.',
    billingAddressDescription: 'Please provide your billing address.',
    stepXofY: 'Step %{step}/%{totalSteps}',
    upgradeThankYou: 'Your %{selectedPlan} subscription is now active. A confirmation email has been sent to your email address.',
    upgradePleaseWait: 'Please wait while we activate your %{selectedPlan} subscription.',
    checkYourEmail: 'Activation is taking a little longer than usual. We are processing your order, and we\'ll send you an email as soon as the subscription is ready.',
    thankYouForYourOrder: 'Thank you for your order!',
    upgradeProblem: 'Oops, there was a problem processing your payment',
    upgradeProblemMessage: 'Your %{selectedPlan} subscription could not be activated because there was a problem processing the payment. Please check your payment details before trying again.',
    upgradeProblemReason: 'The problem is "%{failureMessage}".',
    checkPaymentDetails: 'Check payment details',
    returnToCharts: 'Return to seating charts',
    subscriptionPlanName: '%{name} - %{committedSeats}+ used seats starting at €%{committedEuros}',
    designer: 'Designer',
    chart_scanner: 'Reference chart scanner',
    chart_scanner_description: 'Use the reference chart scanner in the designer to automatically detect seats on a static image.',
    chart_scanner_notice_title: 'Where do I find the scanner?',
    chart_scanner_notice: 'When the scanner is enabled, you\'ll see it after uploading a reference image in the designer.',
    chart_scanner_embedded_designer_description: 'Reference chart scanner just enabled in app.seats.io or in embedded designer as well?',
    scanner_embedded_designer_and_webapp: 'In embedded designer as well',
    scanner_webapp: 'Just in app.seats.io',
    use_object_popovers: 'Use new object popovers',
    use_object_popovers_description: 'Try the new Renderer & Event Manager object popover. Share your feedback before we sunset the legacy tooltip and modals.',
    use_object_popovers_hint: 'Popovers combine the functionality of tooltips and modals into a single, more powerful component.',
    download_credit_note: 'Download credit note %{creditNoteNumber}',
    orderPending: 'Order pending',
    billing_details: 'Billing details',
    update_billing_details: 'Update billing details',
    billing_details_updated: 'Billing details updated',
    billing_details_updated_message: 'Your billing details have been updated',
    something_went_wrong: 'Something went wrong',
    email_companies: 'Email companies',
    set_as_default: 'Set as default payment method',
    remove: 'Remove',
    payment_methods: 'Payment methods',
    expiry_date: 'Expiry date',
    manage_payment_methods: 'Manage payment methods',
    amex: 'American Express',
    diners: 'Diners Club',
    jcb: 'JCB',
    eftpos_au: 'EFTPOS',
    unionpay: 'UnionPay',
    billing: 'Billing',
    error_with_payment_method: 'There was a problem with your payment method',
    change_pricing_plan: 'Change pricing plan',
    current_plan: 'Current plan',
    started_on: 'Started on',
    starting_on: 'Starting on',
    changing_to: 'Changing to',
    renewing_on: 'Renewing on',
    renews_on: 'Renews on',
    ends_on: 'Ends on',
    stop_automatic_renewal: 'Stop automatic renewal',
    current_subscription_uninterrupted: 'This action won\'t interrupt your current subscription.',
    automatic_renewal_stopped: 'Automatic renewal stopped',
    stop_renewal: 'Stop renewal',
    all_data_remains_after_unsubscribing: 'After your subscription ends, all of your data will remain and you can resubscribe again later.',
    cancel_pricing_plan_change: 'Cancel pricing plan change',
    pricing_plan_change_cancelled: 'Pricing plan change cancelled',
    re_enable_automatic_renewal: 'Re-enable automatic renewal',
    automatically_renew_on: 'This will automatically renew your subscription as of %{nextRenewalDate}.',
    automatic_renewal_re_enabled: 'Automatic renewal has been re-enabled',
    re_enable_renewal: 'Re-enable renewal',
    automatic_renewal_on: 'Automatic renewal on',
    will_not_happen: 'will not happen',
    your_subscription_will_continue: 'Your subscription will continue',
    automatic_renewal_contact: 'If you want to re-enable automatic renewal of your subscription, please contact',
    expired: 'Expired',
    AUTOMATIC_SUBSCRIPTION_NOT_ALLOWED: 'Automatic subscription not allowed',
    AUTOMATIC_SUBSCRIPTION_ALLOWED: 'Automatic subscription allowed',
    reactivate_for_subscription: 'Reactivate for subscription',
    reactivate: 'Reactivate',
    SUBSCRIPTION_WINDOW_OPENED: 'Subscription window opened',
    SUBSCRIPTION_WINDOW_CLOSED: 'Subscription window closed',
    reactivation_explanation: 'Your account is currently inactive. You can reactivate it for 24 hours and upgrade to a paid subscription.',
    synced_with_stripe: 'Company details synced with Stripe',
    SYNCED_WITH_STRIPE: 'Synced with Stripe',
    success: 'Success',
    sync_with_stripe: 'Sync company with Stripe',
    link_authenticator_app: 'Link authenticator app',
    disable_two_factor_authentication: 'Disable two-factor authentication',
    two_factor_authentication_disabled: 'Two-factor authentication disabled',
    disable_two_factor_authentication_code_required: 'To disable two-factor authentication, you need to enter the code from your authenticator app.',
    enter_authenticator_code: 'Enter the authenticator code',
    confirm_authenticator_link: 'Confirm authenticator app link',
    two_factor_authentication: 'Two-factor authentication',
    two_factor_authentication_not_enabled: 'Two-factor authentication is not enabled. For additional security, you can use your authenticator app with Seats.io',
    enable_two_factor_authentication: 'Enable two-factor authentication',
    use_an_authenticator_app_on_your_phone_to_link: 'Using an authenticator app on your phone, scan the QR code below to link your Seats.io account. Once this is done, please click on Next.',
    authenticator_code: 'Authenticator code',
    next: 'Next',
    enter_two_factor_authentication_confirmation_code: 'Enter the code currently shown in your authenticator app to ensure the link is correct.',
    authenticator_app_linked: 'Authenticator app linked',
    authenticator_app_link_confirmed: 'Authenticator app link confirmed. Two-factor authenication is now enabled for your account.',
    validate_link: 'Validate link',
    additional_authentication_required: 'Additional authentication required.',
    authenticate: 'Authenticate',
    totp_enabled: 'Two-factor authentication with a time-based one time password is enabled.',
    code_is_required: 'Code is required',
    disable: 'Disable',
}

export default dictionary
