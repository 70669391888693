import axios from 'axios'
import { Environment } from '../environment'
import store from '../store'
import lang from '../utils/lang'
import { getRegionById, getSuperAdminEnvironmentSettings } from '../domain/superAdminEnvironmentSettings'
import { orderBy } from 'lodash'
import { getRegions } from '../domain/regions'
import { parseISO } from 'date-fns'
import { Either } from '../utils/either'

export const adminService = {
    getEnvironmentSettings,
    createSubscriptionEvent,
    deleteSubscriptionEvent,
    getSubscriptionEvents,
    updateSubscriptionEvent,
    getPricingPlans,
    retrieveTimeline,
    getCompany,
    getCompanies,
    getAllCompanies,
    getUsageReport,
    getBillingReport,
    getFeatureAnalyticsReport,
    postComment,
    setWhiteLabel,
    setActive,
    setFeatureFlag,
    setPlan,
    getUserEvents,
    setCompanyFields,
    getInvoices,
    createInvoice,
    removeInvoice,
    copyToAdminAccount,
    syncUsersInProxy,
    getDeploymentPipeline,
    promoteStagingToProduction,
    rollbackDeployment,
    startRegionMigration,
    getJobs,
    enableJob,
    disableJob,
    triggerJob,
    getEmailTemplates,
    confirmEmail,
    emailCompanies,
    syncWithStripe
}

export const apps = {
    CORE_V2: {
        name: "CORE_V2",
        githubRepo: "seatsio-core-v2"
    },
    MESSAGING: {
        name: "MESSAGING",
        githubRepo: "seatsio-messaging"
    },
    UI: {
        name: "UI",
        githubRepo: "seatsio-ui"
    },
    WEBAPP: {
        name: "WEBAPP",
        githubRepo: "seatsio-webapp"
    },
    REPORTING: {
        name: "REPORTING",
        githubRepo: "seatsio-reporting"
    },
    DATACOLLECTOR: {
        name: "DATACOLLECTOR",
        githubRepo: "seatsio-datacollector"
    },
    PROXY: {
        name: "PROXY",
        githubRepo: "seatsio-proxy"
    },
    WEBSITE_V2: {
        name: "WEBSITE_V2",
        githubRepo: "seatsio-website-v2"
    },
    THUMBNAILS: {
        name: "THUMBNAILS",
        githubRepo: "seatsio-thumbnails"
    },
    DEMO_V2: {
        name: "DEMO_V2",
        githubRepo: "seatsio-demo-v2"
    },
    EMERGENCY: {
        name: "EMERGENCY",
        githubRepo: "seatsio-emergency-app"
    }
}

async function getCompany(id, regionId) {
    const url = getRegionById(regionId).url + '/admin/companies/' + id
    const result = await axios({
        method: 'get',
        url,
        auth: {username: store.getState().user.secretKey}
    })
    const company = result.data
    if(company.createdOn) {
        company.createdOn = parseISO(company.createdOn)
    }
    company.region = regionId
    return company
}

async function getPricingPlans() {
    const url = Environment.backendUrl + '/admin/pricing-plans'
    const result = await axios({
        method: 'get',
        url,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function getEnvironmentSettings() {
    const url = Environment.backendUrl + '/admin/environment'
    const result = await axios({
        method: 'get',
        url,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function getInvoices(month, year) {
    return getItemsFromRegions(async region => {
        const url = region.url + '/admin/invoices/' + year + '-' + month
        const result = await axios({
            method: 'get',
            url,
            auth: {username: store.getState().user.secretKey}
        })
        return result.data
    })
}

async function createInvoice(type, month, year, companyId, regionId) {
    const url = `${getRegionById(regionId).url}/admin/invoices/actions/create`
    const result = await axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey},
        data: {type, month, year, companyId}
    })
    result.data.region = regionId
    return result.data
}

async function removeInvoice(invoice) {
    const url = `${getRegionById(invoice.region).url}/admin/invoices/actions/remove/${invoice.id}`
    await axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey}
    })
}

async function getCompanies(filter = null, country = null, startYear = null, startMonth = null, pricingTier = null, isYearly = null, whitelabeled = null, filterRegion = null) {
    const params = {filter, limit: 100}
    if (country) {
        params['filter[country]'] = country
    }
    if (startYear) {
        params['filter[start_year]'] = startYear
    }
    if (startMonth) {
        params['filter[start_month]'] = startMonth
    }
    if (pricingTier) {
        params['filter[pricing_tier]'] = pricingTier
    }
    if (isYearly !== null) {
        params['filter[is_yearly]'] = isYearly
    }
    if (whitelabeled !== null) {
        params['filter[whitelabeled]'] = whitelabeled
    }
    return getItemsFromRegions(async region => {
        const url = region.url + '/admin/companies'
        const result = await axios({
            method: 'get',
            url,
            auth: {username: store.getState().user.secretKey},
            params
        })
        return result.data.items.map(company => {
            if (company.createdOn) {
                company.createdOn = parseISO(company.createdOn)
            }
            return company
        })
    }, r => {
        return filterRegion === null || r.id === filterRegion
    })
}

async function getAllCompanies() {
    return getItemsFromRegions(async region => {
        let allCompanies = []
        let nextPageStartsAfter = null

        while (true) {
            const currentPage = (await axios({
                method: 'get',
                url: region.url + '/admin/companies',
                params: {start_after_id: nextPageStartsAfter, limit: 5000},
                auth: {username: store.getState().user.secretKey}
            })).data

            allCompanies = allCompanies.concat(currentPage.items)

            nextPageStartsAfter = currentPage.next_page_starts_after
            if (!nextPageStartsAfter) {
                return allCompanies
            }
        }
    })
}

async function getUsageReport() {
    const url = Environment.backendUrl + '/admin/reports/usage'
    const result = await axios({
        method: 'get',
        url,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function getBillingReport(month, year) {
    const url = Environment.backendUrl + '/admin/reports/billing/month/' + year + '-' + month
    const result = await axios({
        method: 'get',
        url,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function getFeatureAnalyticsReport() {
    const url= Environment.backendUrl + '/admin/reports/feature-analytics'
    const result = await axios({
        method: 'get',
        url,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function findUserInRegions(email) {
    try {
        return await Promise.any(getRegions().map(async region => {
            const {data: user} = await axios({
                method: 'get',
                url: `${region.url}/admin/users/byEmail/${email}`,
                auth: {username: store.getState().user.secretKey}
            })
            user.region = region.id
            return user
        }))
    } catch (e) {
        if (e.errors.every(error => error.response && error.response.data.errors[0].code === 'USER_NOT_FOUND')) {
            throw e.errors[0]
        }
        throw e
    }
}

export async function copyToAdminAccount(chartKey) {
    const user = store.getState().user
    return copySingleChart(chartKey, user.company.defaultWorkspace.key)
}

export async function copySingleChart(chartKey, toWorkspaceKey) {
    try {
        const chart = await findChartInRegions(chartKey)
        const workspace = await findWorkspaceInRegions(toWorkspaceKey)

        await axios({
            method: 'post',
            url: `${getRegionById(workspace.region).url}/admin/charts/actions/create/workspace/${toWorkspaceKey}`,
            auth: {username: store.getState().user.secretKey},
            data: {
                drawing: chart.publishedDrawing
            }
        })
    } catch (e) {
        if (e.errors.every(error => error.response && error.response.data.errors[0].code === 'CHART_NOT_FOUND')) {
            // eslint-disable-next-line no-throw-literal
            throw {messages: ['#/chartKey: ' + lang.d('chart_not_found')]}
        } else if (e.errors.every(error => error.response && error.response.data.errors[0].code === 'WORKSPACE_NOT_FOUND')) {
            // eslint-disable-next-line no-throw-literal
            throw {messages: ['#/workspaceKey: Could not find a workspace with this key']}
        }
        // eslint-disable-next-line no-throw-literal
        throw {messages: ['Whoops']}
    }
}

export function findChartInRegions(chartKey) {
    return Promise.any(getRegions().map(async region => {
        const {data: chart} = await axios({
            method: 'get',
            url: `${region.url}/admin/charts/${chartKey}`,
            auth: {username: store.getState().user.secretKey}
        })
        chart.region = region.id
        return chart
    }))
}

function findWorkspaceInRegions(workspaceKey) {
    return Promise.any(getRegions().map(async region => {
        const {data: workspace} = await axios({
            method: 'get',
            url: `${region.url}/admin/workspaces/${workspaceKey}`,
            auth: {username: store.getState().user.secretKey}
        })
        workspace.region = region.id
        return workspace
    }))
}

export async function copyChartsByTag(fromEmail, toEmail, tag) {
    try {
        const fromUser = await findUserInRegions(fromEmail)
        const toUser = await findUserInRegions(toEmail)

        if (fromUser.region !== toUser.region) {
            // eslint-disable-next-line no-throw-literal
            throw {messages: ['Cannot copy charts between users from different regions']}
        }

        let url = getRegionById(fromUser.region).url + '/admin/charts/actions/copy/' + fromUser.company.defaultWorkspace.id + '/' + toUser.company.defaultWorkspace.id
        if (tag) {
            url += '?tag=' + encodeURI(tag)
        }
        const copyChartResult = await axios({
            method: 'post',
            url,
            auth: {username: store.getState().user.secretKey}
        })

        return copyChartResult
    } catch (e) {
        const messages = []

        if (e.response && e.response.data.errors) {
            e.response.data.errors.forEach(error => {
                if (error.code === 'USER_NOT_FOUND') {
                    if (error.message.indexOf(fromEmail) > 0) {
                        messages.push('#/fromEmail: ' + error.message)
                    }
                    if (error.message.indexOf(toEmail) > 0) {
                        messages.push('#/toEmail: ' + error.message)
                    }
                }
            })
        }

        // eslint-disable-next-line no-throw-literal
        throw {
            messages: messages.length > 0 ? messages : ['Whoops']
        }
    }
}

function postComment(id, regionId, comment) {
    const url = `${getRegionById(regionId).url}/admin/companies/${id}`
    return axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey},
        data: {comment}
    })
}

function setWhiteLabel(id, regionId, value) {
    const action = value ? 'whitelabel' : 'unwhitelabel'
    const url = `${getRegionById(regionId).url}/admin/companies/${id}/actions/${action}`
    return axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey}
    })
}

function setActive(id, regionId, value) {
    const action = value === 'true' ? 'activate' : 'deactivate'
    const url = `${getRegionById(regionId).url}/admin/companies/${id}/actions/${action}`
    return axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey}
    })
}

function setPlan(id, regionId, value) {
    const url = `${getRegionById(regionId).url}/admin/companies/${id}`
    return axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey},
        data: {subscriptionPlan: value}
    })
}

function setFeatureFlag (id, regionId, key, value) {
    if (value !== undefined) {
        const url = `${getRegionById(regionId).url}/admin/companies/${id}/actions/changefeatureflag`
        return axios({
            method: 'post',
            url,
            auth: { username: store.getState().user.secretKey },
            data: { key: key, value: value.toString() }
        })
    }
}

async function getUserEvents(eventType, startAfterId = null) {
    const url = getSuperAdminEnvironmentSettings().dataCollectorUrl + '/private/events/latest'
    const result = await axios({
        method: 'get',
        url,
        auth: {username: getSuperAdminEnvironmentSettings().dataCollectorSecret},
        params: {start_after_id: startAfterId, limit: 500, event_type: eventType}
    })
    return result.data.map(e => {
        e.date = parseISO(e.date)
        return e
    })
}

function setCompanyFields(id, regionId, data) {
    const url = `${getRegionById(regionId).url}/admin/companies/${id}`
    return axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey},
        data
    })
}

async function createSubscriptionEvent(companyId, regionId, subscriptionEvent) {
    await axios({
        method: 'post',
        url: `${getRegionById(regionId).url}/admin/companies/${companyId}/subscription-events`,
        auth: {username: store.getState().user.secretKey},
        data: {event: subscriptionEvent}
    })
}

async function getSubscriptionEvents(companyId, regionId) {
    const result = await axios({
        method: 'get',
        url: `${getRegionById(regionId).url}/admin/companies/${companyId}/subscription-events`,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function updateSubscriptionEvent(companyId, regionId, originalSubscriptionEvent, updatedSubscriptionEvent) {
    originalSubscriptionEvent.createdOn = undefined
    await axios({
        method: 'post',
        url: `${getRegionById(regionId).url}/admin/companies/${companyId}/subscription-events/update`,
        auth: {username: store.getState().user.secretKey},
        data: {originalEvent: originalSubscriptionEvent, updatedEvent: updatedSubscriptionEvent}
    })
}

async function deleteSubscriptionEvent(companyId, regionId, subscriptionEvent) {
    await axios({
        method: 'delete',
        url: `${getRegionById(regionId).url}/admin/companies/${companyId}/subscription-events`,
        auth: {username: store.getState().user.secretKey},
        data: {month: subscriptionEvent.month, year: subscriptionEvent.year, type: subscriptionEvent.type}
    })
}

async function getItemsFromRegions(fn, filter = _ => true) {
    const regions = getSuperAdminEnvironmentSettings().regions
    const promises = regions.filter(filter).map(fn)
    const allItemsArrays = await Promise.all(promises)
    const allItems = allItemsArrays.flatMap((items, index) => {
        items.forEach(item => item.region = regions[index].id)
        return items
    })
    return orderBy(allItems, 'createdOn', 'desc')
}

async function syncUsersInProxy() {
    await axios({
        method: 'post',
        url: getSuperAdminEnvironmentSettings().proxyUrl + '/users/actions/sync',
        auth: {username: getSuperAdminEnvironmentSettings().proxySecret}
    })
}

async function getJobs(regionId) {
    const result = await axios({
        method: 'get',
        url: `${getRegionById(regionId).url}/admin/jobs`,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function enableJob(regionId, jobName) {
    const url = `${getRegionById(regionId).url}/admin/jobs/${jobName}/actions/enable`
    await axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey}
    })
}

async function disableJob(regionId, jobName) {
    const url = `${getRegionById(regionId).url}/admin/jobs/${jobName}/actions/disable`
    await axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey}
    })
}

async function triggerJob(regionId, jobName) {
    const url = `${getRegionById(regionId).url}/admin/jobs/${jobName}/actions/trigger`
    await axios({
        method: 'post',
        url,
        auth: {username: store.getState().user.secretKey}
    })
}

async function getDeploymentPipeline(app) {
    const result = await axios({
        method: 'get',
        url: getSuperAdminEnvironmentSettings().proxyUrl + '/deployment-pipeline/' + app.name,
        auth: {username: getSuperAdminEnvironmentSettings().proxySecret}
    })
    return result.data
}

async function promoteStagingToProduction(app) {
    await axios({
        method: 'post',
        url: getSuperAdminEnvironmentSettings().proxyUrl + '/deployment-pipeline/'+ app.name +'/actions/promote',
        auth: {username: getSuperAdminEnvironmentSettings().proxySecret}
    })
}

async function rollbackDeployment(app) {
    await axios({
        method: 'post',
        url: getSuperAdminEnvironmentSettings().proxyUrl + '/deployment-pipeline/' + app.name + '/actions/rollback',
        auth: {username: getSuperAdminEnvironmentSettings().proxySecret}
    })
}

function startRegionMigration(companyId, fromRegion, toRegion) {
    return axios({
        method: 'post',
        url: `${getRegionById(toRegion).url}/admin/companies/${companyId}/migration/actions/start`,
        auth: {username: store.getState().user.secretKey},
        data: {
            fromRegion
        }
    })
}

async function getEmailTemplates() {
    const result = await axios({
        method: 'get',
        url: `${Environment.backendUrl}/admin/email-templates`,
        auth: {username: store.getState().user.secretKey}
    })
    return result.data
}

async function retrieveTimeline (id, regionId){
    let response = await axios({
        method: 'get',
        url: `${getRegionById(regionId).url}/admin/companies/${id}/timeline`,
        auth: {username: store.getState().user.secretKey}
    })
    return response.data
}

async function confirmEmail (confirmationCode, regionId) {
    const response = await axios({
        method: 'post',
        url: `${getRegionById(regionId).url}/admin/users/actions/confirm-account/${confirmationCode}`,
        auth: {username: store.getState().user.secretKey}
    })
    return response.data
}

async function emailCompanies (subject, isLegallySignificant, sendOnlyToMe, text, html, pricingPlanIds) {
    const regionsToSendTo = sendOnlyToMe ? [getSuperAdminEnvironmentSettings().regions[0]] : getSuperAdminEnvironmentSettings().regions
    const results = {}
    for (let i = 0; i < regionsToSendTo.length; i++) {
        try {
            await axios({
                method: 'post',
                url: `${regionsToSendTo[i].url}/admin/companies/actions/email-company-admins`,
                auth: {username: store.getState().user.secretKey},
                data: {subject, isLegallySignificant, sendOnlyToMe, text, html, pricingPlanIds: pricingPlanIds.length === 0 ? null : pricingPlanIds}
            })
            results[regionsToSendTo[i].id] = true
        } catch (e) {
            results[regionsToSendTo[i].id] = false
        }
    }
    return results
}

async function syncWithStripe (id, regionId){
    try {
        const response = await axios({
            method: 'post',
            url: `${getRegionById(regionId).url}/admin/companies/${id}/actions/sync-with-stripe`,
            auth: {username: store.getState().user.secretKey}
        })
        return Either.right(lang.d('synced_with_stripe'))
    } catch (e) {
        return Either.left(e.response.data.messages[0])
    }
}
