import axios from 'axios'
import { Environment } from '../environment'
import store from '../store'
import {backendUrlOfActiveCompany, getRegionById, regionOfActiveCompany} from '../domain/regions'
import {Either} from "../utils/either";

export const userService = {
    login,
    signUp,
    resetPassword,
    confirmResetPassword,
    confirmEmail,
    resendConfirmationEmail,
    acceptInvitationForNewUser,
    acceptInvitationForExistingUser,
    updateUser,
    editEmail,
    retrieveCurrentUserForBackgroundUpdate,
    reactivateForSubscription,
    linkAuthenticatorApp,
    confirmLinkWithAuthenticatorApp,
    twoFactorAuthenticationCode,
    disableTwoFactorAuthenticationCode
}

async function getRegionId (email) {
    try {
        const res = await axios.get(Environment.backendUrl + '/system/public/users/region/by-email/' + encodeURIComponent(email))
        return res.data.region
    } catch (e) {
        throw e.response.data
    }
}

async function login (email, password) {
    try {
        const regionId = await getRegionId(email)
        const res = await axios({
            url: getRegionById(regionId).url + '/login',
            method: 'post',
            auth: { username: email, password }
        })
        if (res.data.twoFactorAuthenticationRequired) {
            return Either.left(res.data)
        } else {
            return Either.right(setRegion(res.data, regionId))
        }
    } catch (e) {
        throw e.response.data
    }
}

async function signUp (email, password, regionId) {
    try {
        const res = await axios.post(getRegionById(regionId).url + '/system/public/users', { email, password })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function confirmEmail (confirmationCode, regionId) {
    try {
        const res = await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/confirm-account/${confirmationCode}`)
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function acceptInvitationForNewUser (code, password, regionId) {
    try {
        const res = await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/accept-invitation-for-new-user`, {
            code,
            password
        })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function acceptInvitationForExistingUser (code, regionId) {
    try {
        const res = await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/accept-invitation-for-existing-user`, { code })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function resetPassword (email) {
    const regionId = await getRegionId(email)
    return axios.post(`${getRegionById(regionId).url}/system/public/users/actions/initiate-password-reset/${btoa(email)}`)
}

async function confirmResetPassword (userId, regionId, resettedPasswordCode, newPassword) {
    try {
        return await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/reset-password`, {
            userId,
            resettedPasswordCode,
            newPassword
        })
    } catch (e) {
        throw e.response.data
    }
}

async function resendConfirmationEmail (email) {
    try {
        const regionId = await getRegionId(email)
        await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/resend-confirmation-email/email/${email}`)
    } catch (e) {
        throw e.response.data
    }
}

function editEmail (email) {
    return axios.post(`${backendUrlOfActiveCompany()}/accounts/me/actions/change-email`, { email }, {
        auth: { username: store.getState().user.secretKey }
    })
}

async function updateUser (id, role, workspaces = undefined) {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/users/${id}`,
        auth: { username: store.getState().user.secretKey },
        data: { workspaces: workspaces, role: role },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
    return result.data
}

async function retrieveCurrentUserForBackgroundUpdate () {
    const state = store.getState();
    const company = state && state.context ? state.context.company : null
    if (company) {
        const result = await axios.create({})({
            method: 'get',
            url: `${backendUrlOfActiveCompany()}/accounts/me`,
            auth: { username: state.user.secretKey },
            headers: { 'X-Company-ID': state.context.company.id }
        })
        return setRegion(result.data, regionOfActiveCompany().id)
    } else {
        return null
    }
}

function setRegion (user, regionId) {
    user.company.region = regionId
    return user
}

async function reactivateForSubscription (email, password){
    try {
        const regionId = await getRegionId(email)
        const res = await axios({
            url: getRegionById(regionId).url + '/ready-to-subscribe',
            method: 'post',
            auth: { username: email, password }
        })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function linkAuthenticatorApp () {
    let response = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/2fa/totp/enable`,
        auth: { username: store.getState().user.secretKey }
    })
    return response.data
}

async function confirmLinkWithAuthenticatorApp (code) {
    let response = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/2fa/totp/confirm`,
        data: {code},
        auth: { username: store.getState().user.secretKey }
    })
    return response.data
}

async function twoFactorAuthenticationCode (email, code, token) {
    const regionId = await getRegionId(email)
    let response = await axios({
        method: 'post',
        url: `${getRegionById(regionId).url}/tfa-login`,
        data: {code, token},
    })
    return setRegion(response.data, regionId)
}

async function disableTwoFactorAuthenticationCode (code) {
    let response = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/2fa/disable`,
        data: {code},
        auth: { username: store.getState().user.secretKey },
    })
    return response.data
}
