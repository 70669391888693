import PropTypes from "prop-types";
import React from "react";
import {resetHeader} from "../../actions/header";
import {connect} from "react-redux";
import lang from "../../utils/lang";
import FormButton from "../../components/GUI/FormButton";
import {LinkAuthenticatorApp} from "./LinkAuthenticatorApp";
import {userService} from "../../services/user.service";
import {TwoFactorAuthenticationCode} from "./TwoFactorAuthenticationCode";
import {closeAndClearModalWizard, openModalWizard} from "../../actions/modalWizard";
import {disableTwoFactorAuthentication, enableTwoFactorAuthentication} from "../../actions/twoFactorAuthentication";
import {validateAuthentication} from "../../utils/validators";

class TwoFactorAuthentication extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tfaType:null
        }
    }

    componentDidMount() {
        this.props.resetHeader()
        this.setState({tfaType: this.props.twoFactorAuthenticationType})
    }

    showLinkWithAuthenticatorModal () {
        this.props.openModalWizard({
            successMessage: lang.d('authenticator_app_linked'),
            steps: [
                {
                    title: lang.d('link_authenticator_app'),
                    message: lang.d('use_an_authenticator_app_on_your_phone_to_link'),
                    acceptCaption: lang.d('next'),
                    render: () => <LinkAuthenticatorApp/>,
                },
                {
                    title: lang.d('confirm_authenticator_link'),
                    message: lang.d('enter_two_factor_authentication_confirmation_code'),
                    acceptCaption: lang.d('validate_link'),
                    formik: {
                        initialValues: {
                            code: ''
                        },
                        validate: values => validateAuthentication(values),
                        onBadRequest: (setErrors, errorMessages) => setErrors({code: errorMessages[0]}),
                        render: props => <TwoFactorAuthenticationCode {...props}/>,
                    },
                    onSubmit: values => {
                        return userService.confirmLinkWithAuthenticatorApp(values.code);
                    },
                    onSubmitSuccess: () => {
                        this.props.setTwoFactorAuthenticationEnabled()
                        this.setState({tfaType: 'TOTP'})
                    },
                },
            ],
            settings: {
                dialogClassName: 'two-factor-authentication-wizard'
            }
        })
    }

    showDisableTwoFactorAuthenticationModal () {
        this.props.openModalWizard({
            successMessage: lang.d('two_factor_authentication_disabled'),
            steps: [
                {
                    title: lang.d('disable_two_factor_authentication'),
                    message: lang.d('disable_two_factor_authentication_code_required'),
                    acceptCaption: lang.d('disable'),
                    formik: {
                        initialValues: {
                            code: ''
                        },
                        validate: values => validateAuthentication(values),
                        render: props => <TwoFactorAuthenticationCode {...props}/>,
                        onBadRequest: (setErrors, errorMessages) => setErrors({code: errorMessages[0]})
                    },
                    onSubmit: values => {
                        return userService.disableTwoFactorAuthenticationCode(values.code);
                    },
                    onSubmitSuccess: () => {
                        this.props.setTwoFactorAuthenticationDisabled()
                        this.setState({tfaType: 'NONE'})
                    },
                },
            ],
            settings: {
                dialogClassName: 'two-factor-authentication-wizard'
            }
        })
    }

    renderNoTwoFactorAuthentication () {
        return <div>
            <div className="top-and-bottom-space">{lang.d('two_factor_authentication_not_enabled')}</div>
            <div>
                <FormButton type="button" onClick={this.showLinkWithAuthenticatorModal.bind(this)}>
                    {lang.d('enable_two_factor_authentication')}
                </FormButton>
            </div>
        </div>
    }

    renderDisableTwoFactorAuthentication () {
        return <div>
            <div className="top-and-bottom-space">{lang.d('totp_enabled')}</div>
            <div>
                <FormButton type="button" onClick={this.showDisableTwoFactorAuthenticationModal.bind(this)}>
                    {lang.d('disable_two_factor_authentication')}
                </FormButton>
            </div>
        </div>
    }

    render () {
        return <>
            {(!this.state.tfaType || this.state.tfaType === 'NONE' || this.state.tfaType === 'TOTP_PENDING') && this.renderNoTwoFactorAuthentication()}
            {this.state.tfaType === 'TOTP' && this.renderDisableTwoFactorAuthentication()}
        </>
    }
}

TwoFactorAuthentication.propTypes = {
    twoFactorAuthenticationType: PropTypes.string,
    resetHeader: PropTypes.func.isRequired,
    openModalWizard: PropTypes.func.isRequired,
    closeModalWizard: PropTypes.func.isRequired,
    setTwoFactorAuthenticationEnabled: PropTypes.func.isRequired,
    setTwoFactorAuthenticationDisabled: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    twoFactorAuthenticationType: state.user.twoFactorAuthenticationType
})

const mapDispatchToProps = dispatch => ({
    resetHeader: () => dispatch(resetHeader()),
    openModalWizard: payload => dispatch(openModalWizard(payload)),
    closeModalWizard: payload => dispatch(closeAndClearModalWizard(payload)),
    setTwoFactorAuthenticationEnabled: () => dispatch(enableTwoFactorAuthentication()),
    setTwoFactorAuthenticationDisabled: () => dispatch(disableTwoFactorAuthentication()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthentication)
