import React from 'react'
import PropTypes from 'prop-types'
import SplashScreenModalDialog from '../../components/GUI/SplashScreenModalDialog'
import ReactivateForSubscriptionForm from '../../components/ReactivateForSubscriptionForm'
import { isUndefined } from 'lodash'

class ReactivateForSubscription extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            initialValues: {
                email: isUndefined(props.location.state) ? '' : props.location.state.email,
                password: isUndefined(props.location.state) ? '' : props.location.state.password
            }
        }
    }

    render () {
        return <SplashScreenModalDialog body={<ReactivateForSubscriptionForm initialValues={this.state.initialValues}/>}/>
    }
}

ReactivateForSubscription.propTypes = {
    location: PropTypes.object.isRequired
}

export default ReactivateForSubscription
