import { exposeErrors } from '../utils/errors'
import { adminService } from '../services/admin.service'

export const getTimeline = (id, regionId) =>
    (dispatch, getState) =>
        exposeErrors(adminService.retrieveTimeline(id, regionId), null, getState())

export const getPricingPlans = () =>
    (dispatch, getState) =>
        exposeErrors(adminService.getPricingPlans(), null, getState())

export const getCompany = (id, regionId) =>
    (dispatch, getState) =>
        exposeErrors(adminService.getCompany(id, regionId), null, getState())

export const getCompanies = (searchValue, country, startYear, startMonth, pricingTier, isYearly, whitelabeled, region) =>
    (dispatch, getState) =>
        exposeErrors(adminService.getCompanies(searchValue, country, startYear, startMonth, pricingTier, isYearly, whitelabeled, region), null, getState())

export const setComment = (id, regionId, value) =>
    (dispatch, getState) =>
        exposeErrors(adminService.postComment(id, regionId, value), null, getState())

export const setWhiteLabel = (id, regionId, value) =>
    (dispatch, getState) =>
        exposeErrors(adminService.setWhiteLabel(id, regionId, value), null, getState())

export const setFeatureFlag = (id, regionId, key, value) =>
    (dispatch, getState) =>
        exposeErrors(adminService.setFeatureFlag(id, regionId, key, value), null, getState())

export const setPlan = (id, regionId, value) =>
    (dispatch, getState) =>
        exposeErrors(adminService.setPlan(id, regionId, value), null, getState())

export const setActive = (id, regionId, value) =>
    (dispatch, getState) =>
        exposeErrors(adminService.setActive(id, regionId, value), null, getState())

export const setCompanyFields = (id, regionId, data) =>
    (dispatch, getState) =>
        exposeErrors(adminService.setCompanyFields(id, regionId, data), null, getState())
