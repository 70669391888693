import React from "react";
import {userService} from "../../services/user.service";

class LinkAuthenticatorApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            qrCode: null
        }
    }

    async componentDidMount() {
        const qrCode = await userService.linkAuthenticatorApp()
        this.setState({qrCode})
    }

    render () {
        return <div>
            {this.state.qrCode && <img alt="QR code to link with authenticator app" src={`data:image/png;base64,${this.state.qrCode}`}></img>}
        </div>
    }
}

export {
    LinkAuthenticatorApp
}
