export const enableTwoFactorAuthentication = () => ({
    type: 'TWO_FACTOR_AUTHENTICATION/ENABLE',
    payload: {
        twoFactorAuthenticationType: 'TOTP'
    }
})

export const disableTwoFactorAuthentication = () => ({
    type: 'TWO_FACTOR_AUTHENTICATION/DISABLE',
    payload: {
        twoFactorAuthenticationType: 'NONE'
    }
})
