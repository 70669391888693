import React, { Component } from 'react'
import lang from '../../../utils/lang'
import OptionSwitcher from '../../../components/GUI/OptionSwitcher'
import * as PropTypes from 'prop-types'
import { setCompanySetting } from '../../../actions/user'
import { connect } from 'react-redux'
import { exposeErrors } from '../../../utils/errors'
import { changeSetting } from '../../../services/accounts.service'

class DesignerCompanySettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            updatingHoldPeriod: false,
            updatingGenerateShortHoldTokens: false,
            updatingAllowPublicHoldTokenCreation: true,
            updatingMaxHoldsPerToken: false
        }
    }

    render() {
        return <>
            <div className="setting-page-title">{ lang.d('designer') }</div>
            {this.renderChartScanner()}
        </>
    }

    renderChartScanner() {
        return <div className="setting">
            <div className="notice">
                <div className="title">{lang.d('chart_scanner_notice_title')}</div>
                <div className="description">
                    <p>{lang.d('chart_scanner_notice')}</p>
                </div>
            </div>
            <div className="title">{lang.d('chart_scanner')}</div>
            <div className="text">{lang.d('chart_scanner_description')}</div>
            <OptionSwitcher
                value={this.props.setting.canUseReferenceChartScanner.toString().toUpperCase()}
                options={{
                    TRUE: lang.d('enabled'),
                    FALSE: lang.d('disabled')
                }}
                loading={this.state.updatingCanUseReferenceChartScanner}
                onChange={value => this.setUseReferenceChartScanner(value)}
            />
            <div className="text">{lang.d('chart_scanner_embedded_designer_description')}</div>
            <OptionSwitcher
                value={this.props.setting.showReferenceChartScannerInEmbeddedDesigner.toString().toUpperCase()}
                options={{
                    FALSE: lang.d('scanner_webapp'),
                    TRUE: lang.d('scanner_embedded_designer_and_webapp')
                }}
                disabled={!this.props.setting.canUseReferenceChartScanner}
                loading={this.state.updatingShowReferenceChartScannerInEmbeddedDesigner}
                onChange={value => this.setShowReferenceChartScannerInEmbeddedDesigner(value)}
            />
        </div>
    }

    async setUseReferenceChartScanner (value) {
        const oldValue = this.props.setting.canUseReferenceChartScanner
        const newValue = value === 'TRUE'
        this.props.setSetting('canUseReferenceChartScanner', newValue)

        this.setState({
            updatingCanUseReferenceChartScanner: true
        })

        try {
            await exposeErrors(changeSetting('CAN_USE_REFERENCE_CHART_SCANNER', value), () => this.props.setSetting('canUseReferenceChartScanner', oldValue))
            this.props.setSetting('canUseReferenceChartScanner', newValue)
        } finally {
            this.setState({
                updatingCanUseReferenceChartScanner: false
            })
        }
    }

    async setShowReferenceChartScannerInEmbeddedDesigner (value) {
        const oldValue = this.props.setting.showReferenceChartScannerInEmbeddedDesigner
        const newValue = value === 'TRUE'
        this.props.setSetting('showReferenceChartScannerInEmbeddedDesigner', newValue)

        this.setState({
            updatingShowReferenceChartScannerInEmbeddedDesigner: true
        })

        try {
            await exposeErrors(changeSetting('SHOW_REFERENCE_CHART_SCANNER_EMBEDDED_DESIGNER', value), () => this.props.setSetting('showReferenceChartScannerInEmbeddedDesigner', oldValue))
            this.props.setSetting('showReferenceChartScannerInEmbeddedDesigner', newValue)
        } finally {
            this.setState({
                updatingShowReferenceChartScannerInEmbeddedDesigner: false
            })
        }
    }
}


DesignerCompanySettings.propTypes = {
    setting: PropTypes.object.isRequired,
    setSetting: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings
})

const mapDispatchToProps = dispatch => ({
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesignerCompanySettings)
