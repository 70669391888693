// @ts-check
import React, { Component } from 'react'
import { history } from '../../store'
import { SeatsioDesigner } from '@seatsio/seatsio-react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { resetHeader } from '../../actions/header'
import { setPendingRefreshChart } from '../../actions/charts'
import { chartJSUrlOfActiveCompany } from '../../domain/regions'

class EditChart extends Component {
    componentDidMount () {
        this.props.resetHeader()
    }

    getChartKey () {
        return this.props.match.params.chartKey
    }

    goBack () {
        if (history.location.state !== undefined && history.location.state.fromSeatsio) history.goBack()
        else history.push('/')
    }

    onChartUpdated () {
        this.props.setPendingRefreshChart(this.getChartKey())
    }

    render () {
        return (
            <div className="chartDesignerContainer">
                <div className="designer">
                    <SeatsioDesigner
                        region="eu"
                        chartKey={this.getChartKey()}
                        secretKey={this.props.secretKey}
                        onExitRequested={() => this.goBack()}
                        chartJsUrl={chartJSUrlOfActiveCompany()}
                        openLatestDrawing={true}
                        onChartUpdated={() => this.onChartUpdated()}
                        // @ts-expect-error
                        workspaceKey={this.props.workspaceKey}
                        className="designer"
                        isWebapp={true}
                    />
                </div>
            </div>
        )
    }
}

EditChart.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    ready: PropTypes.bool,
    resetHeader: PropTypes.func,
    setPendingRefreshChart: PropTypes.func,
    secretKey: PropTypes.string.isRequired,
    workspaceKey: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    secretKey: state.user.secretKey,
    workspaceKey: state.context.workspace.key
})

const mapDispatchToProps = dispatch => ({
    resetHeader: payload => dispatch(resetHeader(payload)),
    setPendingRefreshChart: payload => dispatch(setPendingRefreshChart(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditChart)
