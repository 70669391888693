import React from "react";
import {Formik} from "formik";
import lang from "../../utils/lang";
import * as PropTypes from "prop-types";
import LoadingIndicator from "./LoadingIndicator";
import {parseBackendErrors} from "../../utils/backendErrors";
import {setModalWizardSubmitting} from "../../actions/modalWizard";

class ModalWizard extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            currentStep: 0
        }
    }

    moveToNextStep () {
        this.setState({currentStep: this.state.currentStep + 1})
    }

    renderWaiting () {
        return <div className={`waiting-indicator ${this.props.isWaitingCompleted && 'done'}`}>
            <div className="done-indicator">
                <div className="stamp">
                    <div className="stroke"></div>
                    <div className={`icon icon-${this.props.doneIcon}`}></div>
                </div>
                <div className="message">{ this.props.doneMessage }</div>
            </div>
            <LoadingIndicator />
        </div>
    }

    renderComponentContents (step) {
        return <form>
            { this.props.steps[this.state.currentStep].title && <div className="title">{ step.title }</div> }
            { this.props.steps[this.state.currentStep].message && <div className="message">{ step.message }</div> }
            { step.render() }

            <div className="actions">
                { this.renderComponentActionButtons(step) }
            </div>
        </form>
    }

    renderComponentActionButtons (step) {
        return <>
            <button type="button" className="action" onClick={() => this.props.onCancel()}>
                {this.props.settings.cancelCaption || lang.d('cancel')}
            </button>
            <button
                type="button"
                className={`action highlighted ${step.dangerousAction && 'danger'}`}
                onClick={_ => this.handleComponentAction(step)}
            >
                {step.acceptCaption || lang.d('accept')}
            </button>
        </>
    }

    handleComponentAction (step) {
        if (step.handle) {
            step.handle()
        }
        this.moveToNextStep()
    }

    renderFormikContents (step) {
        return <Formik
            initialValues={step.formik.initialValues}
            validate={values => step.formik.validate ? step.formik.validate(values) : {}}
            onSubmit={(values, { setSubmitting, setErrors, setStatus }) => {
                setSubmitting(true)
                setModalWizardSubmitting(true)
                try {
                    let step = this.props.steps[this.state.currentStep];
                    step.onSubmit(values).then(onSubmitResponse => {
                        setStatus('submitted')
                        setSubmitting(false)
                        step.onSubmitSuccess && step.onSubmitSuccess(values, onSubmitResponse)
                        if (this.state.currentStep + 1 === this.props.steps.length) {
                            this.props.showSuccessMessageAndClose(this.props.settings.doneIcon)
                        } else {
                            this.moveToNextStep()
                        }
                    }).catch(error => {
                        if (error.request && error.request.status === 0) {
                            setErrors({ server: 'Network Error' })
                        } else {
                            const errorMessages = error.messages || error.response.data.messages
                            const backendErrors = parseBackendErrors(errorMessages)
                            step.formik.onBadRequest(setErrors, errorMessages, backendErrors)
                        }
                        setStatus(null)
                    })
                } finally {
                    setSubmitting(false)
                    setModalWizardSubmitting(false)
                }
            }}
        >
            {formikProps => this.renderFormikForm(step, formikProps)}
        </Formik>
    }

    renderFormikForm (step, formikProps) {
        return <form onSubmit={formikProps.handleSubmit}>
            { step.title && <div className="title">{ step.title }</div> }
            { step.message && <div className="message">{ step.message }</div> }
            { step.formik.render(formikProps) }

            { formikProps.errors.server &&
                <div className="errors">
                    <div className="error">{formikProps.errors.server}</div>
                </div>
            }

            <div className="actions">
                { this.props.waiting || (formikProps.isSubmitting || formikProps.status === 'submitted')
                    ? this.renderWaiting()
                    : this.renderFormikActionButtons(step)
                }
            </div>
        </form>
    }

    renderFormikActionButtons (step) {
        return <>
            <button type="button" className="action" onClick={() => this.props.onCancel()}>
                {lang.d('cancel')}
            </button>
            <button
                type="submit"
                className={`action highlighted ${step.dangerousAction && 'danger'}`}
            >
                {step.acceptCaption || lang.d('accept')}
            </button>
        </>
    }

    render () {
        return <div className={`NewModalWizard ${this.props.closed && 'closed'}`}>
            {this.props.visible && <>
                <div className="backdrop" onClick={this.props.onCancel}></div>
                <div className={`wizard ${this.props.settings.dialogClassName && this.props.settings.dialogClassName}`}>
                    { this.props.steps[this.state.currentStep].render && this.renderComponentContents(this.props.steps[this.state.currentStep]) }
                    { this.props.steps[this.state.currentStep].formik &&  this.renderFormikContents(this.props.steps[this.state.currentStep])}
                </div>
            </>}
        </div>
    }
}

ModalWizard.propTypes = {
    doneIcon: PropTypes.string,
    closed: PropTypes.bool,
    waiting: PropTypes.bool,
    settings: PropTypes.object,
    doneMessage: PropTypes.string,
    visible: PropTypes.bool,
    isWaitingCompleted: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    showSuccessMessageAndClose: PropTypes.func,
    setModalSubmitting: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ModalWizard
