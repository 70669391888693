import React from 'react'
import SplashScreenModalDialog from '../../components/GUI/SplashScreenModalDialog'
import AuthenticateWithTwoFactorForm from "../../components/AuthenticateWithTwoFactorForm";
import * as PropTypes from "prop-types";

const TwoFactorAuthenticate = (props) => {
    return <SplashScreenModalDialog body={<AuthenticateWithTwoFactorForm email={props.location.state.email} token={props.location.state.token}/>}/>
}

TwoFactorAuthenticate.propTypes = {
    location: PropTypes.object.isRequired,
}

export default TwoFactorAuthenticate
