import {MODAL_WIZARD_CLEAR_TIMEOUT} from "../constants/modalWizard";

export const openModalWizard = (payload) => ({
    type: 'MODAL_WIZARD/OPEN',
    payload: {
        ...payload,
        newModal: true
    }
})

export const closeModalWizard = () => ({
    type: 'MODAL_WIZARD/CLOSE'
})

export const clearModalWizard = () => ({
    type: 'MODAL_WIZARD/CLEAR'
})

export const onModalWizardChange = (payload) => ({
    type: 'MODAL_WIZARD/ON_CHANGE',
    payload
})

export const onModalWizardSuccess = payload => ({
    type: 'MODAL_WIZARD/ON_SUCCESS',
    payload
})

export const setModalWizardSubmitting = (payload) => ({
    type: 'MODAL_WIZARD/SET_SUBMITTING',
    payload
})

export const setModalWizardSubmitEnabled = enabled => ({
    type: 'MODAL_WIZARD/SET_SUBMIT_ENABLED',
    payload: enabled
})

export const closeWizard = () => ({ type: 'MODAL_WIZARD/CLOSE' })
export const modalWizardAccepted = () => ({ type: 'MODAL_WIZARD/ACCEPTED' })
export const closeAndClearModalWizard = () => async (dispatch) => {
    dispatch(closeWizard())
    setTimeout(() => {
        dispatch(clearModalWizard())
    }, MODAL_WIZARD_CLEAR_TIMEOUT)
}
