import { Component } from 'react'
import PropTypes from 'prop-types'
import { updateUser } from '../actions/user'
import { connect } from 'react-redux'
import { userService } from '../services/user.service'

class OnLoadStateRefresher extends Component {

    async componentDidMount () {
        const user = await userService.retrieveCurrentUserForBackgroundUpdate()
        if (user !== null) {
            this.props.updateUser(user)
        }
    }

    render () {
        return <></>
    }
}

OnLoadStateRefresher.propTypes = {
    updateUser: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    updateUser: payload => dispatch(updateUser(payload))
})

export default connect(null, mapDispatchToProps)(OnLoadStateRefresher)
