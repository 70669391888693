export const defaultState = null

const context = (state = defaultState, action) => {
    switch (action.type) {
    case 'SWITCH_TO_WORKSPACE_AND_COMPANY': {
        return {
            company: { ...action.company },
            workspace: { ...action.workspace },
            isDefaultContextOfUser: action.isDefaultContextOfUser,
            isDefaultWorkspaceOfCompany: action.isDefaultWorkspaceOfCompany,
            exitPath: action.path
        }
    }
    case 'SET_SINGLE_COMPANY_SETTING': {
        const newState = { ...state }
        newState.company.settings[action.setting] = action.value
        return newState
    }
    case 'SET_SINGLE_WORKSPACE_SETTING': {
        const newState = { ...state }
        newState.workspace.overriddenSettings[action.setting] = action.value
        return newState
    }
    case 'DELETE_SINGLE_WORKSPACE_SETTING': {
        const newState = { ...state }
        delete newState.workspace.overriddenSettings[action.setting]
        return newState
    }
    case 'SET_COMPANY_VALIDATION_SETTING': {
        const newState = { ...state }
        newState.company.settings.chartValidation[action.setting] = action.value
        return newState
    }
    case 'SET_COMPANY_DEFAULT_RENDERER_SETTING': {
        const newState = { ...state }
        newState.company.settings.defaultRendererSettings[action.setting] = action.value
        return newState
    }
    case 'SET_CONTEXT_COMPANY_ADMIN_KEY':
        return {
            ...state,
            company: { ...state.company, adminKey: action.adminKey }
        }
    case 'SET_EXIT_PATH': {
        return {
            ...state,
            exitPath: action.pathname
        }
    }
    case 'SET_UPCOMING_SUBSCRIPTION_PLAN':
        return {
            ...state,
            company: {
                ...state.company,
                upcomingSubscriptionPlan: action.upcomingSubscriptionPlan
            }
        }
    case 'SET_HAS_CHOSEN_SUBSCRIPTION_PLAN':
        return {
            ...state,
            company: {
                ...state.company,
                hasChosenSubscriptionPlan: true
            }
        }
    case 'SET_HAS_SUBSCRIBED':
        return {
            ...state,
            company: {
                ...state.company,
                hasEverSubscribed: true,
                isCurrentlySubscribed: true
            }
        }
    default:
        return state
    }
}

export default context
