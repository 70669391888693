import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import lang from '../../utils/lang'
import last from 'lodash/last'
import { wrapAround } from '../../utils/helperFunctions'

const SUGGESTIONS_SEPARATOR = '---'

class TextInput extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            editing: false,
            suggestionsPointer: -1,
            typedValue: ''
        }
        this.editing = false
        this.onChange = this.onChange.bind(this)
        this.onEditEnd = this.onEditEnd.bind(this)
        this.inputRef = React.createRef()
    }

    static defaultProps = {
        showLoadingIndicator: false
    }

    isReadOnly () {
        return this.props.locked || this.props.disabled
    }

    onKeyDown (e) {
        switch (e.key) {
        case 'Escape':
            if (this.props.escCancelsEdit) {
                this.cancelEdit()
            }
            break
        case 'Enter':
            e.target.blur()
            e.preventDefault()
            e.stopPropagation()
            if (this.props.onEnter) {
                this.props.onEnter(this.state.typedValue)
            }
            break
        case 'ArrowUp':
        case 'ArrowDown':
            if (this.usingSuggestions()) {
                e.preventDefault()
                e.stopPropagation()
                this.moveSuggestionsPointer(e.key === 'ArrowDown' ? 1 : -1)
            }
            break
        default:
            break
        }
    }

    moveSuggestionsPointer (mod) {
        let nextPointer = this.state.suggestionsPointer + mod
        const suggestions = this.getSuggestions()
        if (suggestions[nextPointer] === SUGGESTIONS_SEPARATOR) {
            nextPointer += mod / Math.abs(mod)
        }
        nextPointer = wrapAround(
            nextPointer,
            0,
            suggestions.length
        )
        this.setState({
            suggestionsPointer: nextPointer
        })
        const nextValue = suggestions[nextPointer]
        this.setCurrentValue(nextValue)
    }

    onEditStart () {
        this.newValue = this.getValue()
        this.initialValue = this.newValue
        this.setState({
            editing: true,
            suggestionsPointer: -1,
            typedValue: this.inputRef.current ? this.inputRef.current.value : ''
        })
        this.editing = true
        if (this.props.onEditStart) {
            this.props.onEditStart()
        }
    }

    onEditStartAndSelect (e) {
        e.preventDefault()
        this.onEditStart()
        this.inputRef.current.select()
    }

    onEditEnd () {
        if (this.props.submitOnBlur) {
            this.submitChanges()
        }
    }

    cancelEdit () {
        this.newValue = null
        this.setState({
            editing: false,
            typedValue: this.inputRef.current.value
        })
        this.editing = false
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    onChange (e) {
        this.newValue = e.target.value
        this.setState({ typedValue: this.newValue })
        this.submitChanges(false)
    }

    setCurrentValue (value) {
        this.newValue = value
        this.inputRef.current.value = value
    }

    clearValue () {
        if (!this.props.showLoadingIndicator) {
            this.newValue = ''
            this.inputRef.current.value = ''
            this.setState({ editing: false })
        }
    }

    onClearValue () {
        this.clearValue()
        this.submitChanges()
    }

    submitChanges (final = true) {
        if (!this.editing) {
            return
        }
        if (!final && this.newValue !== this.getValue() && this.props.onChangePreview) {
            this.props.onChangePreview(this.newValue)
        }
        if (final && this.props.onChangeFinal) {
            this.props.onChangeFinal(this.newValue)
        }
        if (this.props.onChange) {
            this.props.onChange(this.newValue)
        }
    }

    getValue () {
        return Array.isArray(this.props.value) ? '' : this.props.value
    }

    useSuggestion (suggestion) {
        this.setCurrentValue(suggestion)
        this.submitChanges()
        this.editing = false
    }

    getSuggestions () {
        const suggestions = this.props.suggestions || []
        if (last(suggestions) === SUGGESTIONS_SEPARATOR) {
            suggestions.pop()
        }
        if (this.state.typedValue.length > 0) {
            return suggestions.filter(suggestion => suggestion.toUpperCase().includes(this.state.typedValue.toUpperCase()))
        }
        return suggestions
    }

    usingSuggestions () {
        return this.getSuggestions().length > 0
    }

    handleFocus (e) {
        this.onEditStart()
        e.target.select()
    }

    render () {
        if (this.props.invisible) {
            return null
        }

        let placeholder, title
        let multipleValues = false
        const value = this.getValue()

        if (Array.isArray(this.props.value)) {
            multipleValues = true
            placeholder = this.props.value.join(', ')
            title = lang.d('multiple-values') + ': ' + placeholder
        } else {
            placeholder = this.props.placeholder
            title = this.props.tooltip
        }

        return <div
            className={
                classnames(
                    `TextInput preset-${this.props.type}`,
                    { disabled: this.isReadOnly() },
                    { editing: this.state.editing },
                    { 'auto-expand': this.props.autoExpand },
                    { loading: this.props.loading }
                )
            }>
            { this.props.caption &&
            <label className="caption" onMouseUp={e => this.onEditStartAndSelect(e)} htmlFor={this.props.for}>
                { this.props.caption }
            </label>
            }
            <div className="value">
                <input
                    type={this.props.type || 'text'}
                    id={this.props.for || this.props.caption}
                    defaultValue={value}
                    onKeyDown={e => this.onKeyDown(e)}
                    onChange={this.onChange}
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.onEditEnd}
                    placeholder={placeholder}
                    autoFocus={this.props.autoFocus}
                    title={title}
                    disabled={this.isReadOnly()}
                    autoComplete={this.usingSuggestions() ? 'off' : ''}
                    ref={this.inputRef}
                    tabIndex={this.props.tabIndex}
                />
                { multipleValues && !this.isReadOnly() &&
                <div className="action clear" title={lang.d('clear')} onClick={() => this.onClearValue()}>
                    <span className="icon-close-light"></span>
                </div>
                }
                { this.props.locked &&
                <div className="action edit" title={lang.d('edit')} onClick={() => this.props.onLockClicked && this.props.onLockClicked()}>
                    <span className="icon-edit"></span>
                </div>
                }
                { this.props.action &&
                <div className="action">
                    <button title={this.props.actionTitle} disabled={!this.props.actionEnabled} onClick={() => this.props.action()}><span className={`icon-${this.props.actionIcon}`}></span></button>
                </div>
                }
            </div>
            { this.usingSuggestions() && this.state.editing &&
            <div className="suggestions" onMouseOver={() => this.setState({ suggestionsPointer: -1 })}>
                {
                    this.getSuggestions().map((suggestion, i) =>
                        suggestion === SUGGESTIONS_SEPARATOR
                            ? <div key={`separator-${i}`} className="separator"></div>
                            : <div key={suggestion}
                                className={`item ${this.state.suggestionsPointer === i && 'selected'} ${suggestion === value && 'checked'}`}
                                onMouseDown={() => this.useSuggestion(suggestion)}
                                title={suggestion}>
                                {suggestion}
                            </div>
                    )
                }
            </div>
            }
        </div>
    }
}

TextInput.propTypes = {
    onLockClicked: PropTypes.any,
    caption: PropTypes.string,
    autoFocus: PropTypes.bool,
    locked: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    suggestions: PropTypes.array,
    invisible: PropTypes.bool,
    autoExpand: PropTypes.bool,
    value: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onChangePreview: PropTypes.func,
    onChangeFinal: PropTypes.func,
    onEditStart: PropTypes.func,
    onEditEnd: PropTypes.func,
    escCancelsEdit: PropTypes.bool,
    loading: PropTypes.bool,
    showLoadingIndicator: PropTypes.bool,
    onEnter: PropTypes.func,
    onCancel: PropTypes.func,
    submitOnBlur: PropTypes.bool,
    tabIndex: PropTypes.number,
    for: PropTypes.string,
    actionIcon: PropTypes.string,
    actionTitle: PropTypes.string,
    action: PropTypes.func,
    actionEnabled: PropTypes.bool
}

TextInput.defaultProps = {
    submitOnBlur: false
}

export default TextInput
